import { Separator } from "@/components/ui/separator";
import { SidebarNav } from "./sidebar-nav";
import { Route, Router, Switch, useParams } from "wouter";
import { GeneralPage } from "./general/page";
// import EmployeesPage from "./employees/page";
import useSWR from "swr";
import { Loader } from "lucide-react";
import { LoadingError } from "@/components/LoadingError";
import { NotFound } from "@/components/NotFound";
import { getRoute } from "@/lib/routes";
import { TableType, get } from "@/lib/supabase";
import GoalsPage from "./goals/page";

export const EditQuotaPage = () => {
  const { id } = useParams();
  const {
    data: quota,
    error,
    isLoading,
    mutate,
  } = useSWR(["quotas" as TableType, id], ([table, id]) => get(table, id));
  const sidebarNavItems = [
    {
      title: "General",
      href: getRoute("quotas", "quota", id || "new"),
    },
    {
      title: "Sales Goals",
      href: `${id ? getRoute("quotas", "quota", id, "goals") : "#"}`,
    },
    // {
    //   title: "Employees",
    //   href: `${id ? getRoute("quotas", "quota", id, "employees") : "#"}`,
    // },
  ];
  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <LoadingError />;
  }

  return (
    <>
      <div className="hidden space-y-6 p-10 pb-16 md:block">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">
            {quota?.name || "New sales quota"}
          </h2>
          <p className="text-muted-foreground">
            {quota
              ? "Set your quota limits and assign to your employees."
              : "Create a new quota to start rewarding employee performance."}
          </p>
        </div>
        <Separator className="my-6" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="-mx-4 lg:w-1/5">
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="flex-1 lg:max-w-4xl">
            {
              <Switch>
                <Route path="/quotas/quota/new">
                  <GeneralPage mutate={mutate} />
                </Route>
                <Route path="/quotas/quota/:id">
                  {quota && <GeneralPage quota={quota} mutate={mutate} />}
                </Route>
                {/* <Route path={`/quotas/quota/:id/employees`}>
                  {quota && <EmployeesPage quota={quota} mutate={mutate} />}
                </Route> */}
                <Router base={`/quotas/quota/${id}/goals`}>
                  {quota && (
                    <Route path="/*?">
                      <GoalsPage quota={quota} mutate={mutate} />
                    </Route>
                  )}
                </Router>
              </Switch>
            }
          </div>
        </div>
      </div>
    </>
  );
};
