import { Database, NestedExchangeRate } from "@revelate/types";
import { supabaseClient } from "./client";
import { getTableColums } from "./shared";

export const upsertExchangeRate = async (
  exchange_rate: Database["public"]["Tables"]["exchange_rates"]["Insert"]
) => {
  const query = supabaseClient.from("exchange_rates").upsert(exchange_rate);
  const { data, error } = await query
    .select(getTableColums("exchange_rates"))
    .returns<NestedExchangeRate[]>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};
