import * as React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { AuthProvider } from "@saas-ui/auth";
import { createAuthService } from "@saas-ui/supabase";
import "./index.css";
import { supabaseClient } from "./lib/supabase/client";
import { Toaster } from "@/components/ui/toaster";
import { TODO } from "@revelate/types";

import * as Sentry from "@sentry/react";

Sentry.init({
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === "production",
  dsn: "https://a7a922276f4bd5967d4866beb64883fe@o4507820797329408.ingest.de.sentry.io/4507820799426640",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider {...createAuthService(supabaseClient as TODO)}>
      <App />
      <Toaster />
    </AuthProvider>
  </React.StrictMode>
);
