import { useLocation } from "wouter";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useForm } from "react-hook-form";
import { Database, NestedQuota } from "@revelate/types";
import type { KeyedMutator } from "swr";
import { useAppStore } from "@/stores";
import { getRoute } from "@/lib/routes";
import { upsertQuota } from "@/lib/supabase/quotas";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  CALCULATION_PERIODS,
  TARGET_METRICS,
  getCalculationPeriodValues,
  getQuotaValues,
} from "@/lib/app";
import { zodEnum } from "@/lib/utils";
import { dealTypesRadioButtons } from "@/lib/app";
import { useJune } from "@/hooks/useJune";

const profileFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
  target_metric: z.enum(zodEnum(getQuotaValues() as string[]), {
    required_error: "Please pick a target metric.",
  }),
  calculation_period: z.enum(
    zodEnum(getCalculationPeriodValues() as string[]),
    {
      required_error: "Please pick a calculation period.",
    }
  ),
  deal_types: z
    .array(z.string())
    .refine((value) => value.some((item) => item), {
      message: "You have to select at least one item.",
    }),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export const GeneralForm = ({
  quota: editQuota,
  mutate,
}: {
  quota?: NestedQuota;
  mutate: KeyedMutator<NestedQuota | null>;
}) => {
  const [, setLocation] = useLocation();
  const currentUser = useAppStore((state) => state.currentUser);
  const analytics = useJune();

  async function onSubmit(data: ProfileFormValues) {
    if (!currentUser || !currentUser.company_id) return;
    const quotaData: Database["public"]["Tables"]["quotas"]["Insert"] = {
      id: editQuota?.id,
      company_id: currentUser.company_id, // Required
      name: data.name,
      deal_types: data.deal_types,
      target_metric: data.target_metric,
      calculation_period: data.calculation_period,
    };
    const quota = await upsertQuota(quotaData);
    if (!quota) return;
    // Track quota changes
    analytics?.track(editQuota ? "Quota edited" : "Quota created", {
      id: quota.id,
      name: quota.name,
      deal_types: quota.deal_types,
      target_metric: quota.target_metric,
    });
    mutate({ ...quota });
    setLocation(getRoute("quotas", "quota", quota.id.toString(), "goals"));
  }

  // // This can come from your database or API.
  const defaultValues: Partial<ProfileFormValues> = {
    name: editQuota?.name || "",
    target_metric: editQuota?.target_metric || "",
    deal_types: editQuota?.deal_types || ["new_business", "existing_business"],
    calculation_period: editQuota?.calculation_period || "current_period",
  };

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="Monthly sales quota" {...field} />
              </FormControl>
              <FormDescription className="text-xs">
                Give your quota a name that will help you identify it.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="target_metric"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Target Metric</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Pick a target metric" />
                  </SelectTrigger>
                  <SelectContent>
                    {TARGET_METRICS.map((item, index) => (
                      <SelectItem key={index} value={item.value}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormDescription className="text-xs">
                Pick the type of target you want to set.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="calculation_period"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Calculation Period</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Pick a calculation period" />
                  </SelectTrigger>
                  <SelectContent>
                    {CALCULATION_PERIODS.map((item, index) => (
                      <SelectItem key={index} value={item.value}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormDescription className="text-xs">
                Pick the calculation period for the quota. Defaults to the
                frequency (Month/Quarter/Year) set in the compensation plan.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="deal_types"
          render={() => (
            <FormItem>
              <FormLabel>Deal types</FormLabel>
              {dealTypesRadioButtons.map((item) => (
                <FormField
                  key={item.value}
                  control={form.control}
                  name="deal_types"
                  render={({ field }) => {
                    return (
                      <FormItem
                        key={item.value}
                        className="flex flex-row items-start space-x-3 space-y-0"
                      >
                        <FormControl>
                          <Checkbox
                            checked={field.value?.includes(item.value)}
                            onCheckedChange={(checked) => {
                              return checked
                                ? field.onChange([...field.value, item.value])
                                : field.onChange(
                                    field.value?.filter(
                                      (value) => value !== item.value
                                    )
                                  );
                            }}
                          />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {item.label} Customers
                        </FormLabel>
                      </FormItem>
                    );
                  }}
                />
              ))}
              <FormMessage />
              <FormDescription className="text-xs">
                Choose which type of deals to include when calculating target
                attainment.
              </FormDescription>
            </FormItem>
          )}
        />

        <Button type="submit" className="bg-[#6B6397]">
          Continue
        </Button>
      </form>
    </Form>
  );
};
