import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppStore } from "@/stores";
import { Copy } from "lucide-react";
import { useState } from "react";

export const Scope = () => {
  const scope = useAppStore((state) => state.scope);

  const [linkCopied, setLinkCopied] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const copyUrl = async () => {
    await navigator.clipboard.writeText(location.href);
    setLinkCopied(true);
  };

  return (
    <div className="flex items-center">
      <Avatar className="h-16 w-16">
        <AvatarImage src={scope?.avatar} alt="Avatar" />
        <AvatarFallback>{scope?.avatar_fallback}</AvatarFallback>
      </Avatar>
      <div className="ml-4 space-y-1">
        <div className="group flex items-center gap-2 text-lg">
          <div>
            <p className="text-2xl font-semibold tracking-tight leading-none">
              {scope?.label}
            </p>
          </div>
          <Tooltip delayDuration={700} open={tooltipOpen}>
            <TooltipTrigger asChild>
              <Button
                onClick={() => copyUrl()}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => {
                  setTooltipOpen(false);
                  setTimeout(() => setLinkCopied(false), 300);
                }}
                size="icon"
                variant="outline"
                className="h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100"
              >
                <Copy className="h-3 w-3" />
                <span className="sr-only">Copy link</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right">
              {linkCopied ? "Link copied!" : "Copy link"}
            </TooltipContent>
          </Tooltip>
        </div>
        <p className="text-md text-muted-foreground">{scope?.description}</p>
      </div>
    </div>
  );
};
