import { Separator } from "@/components/ui/separator";
import { GeneralForm } from "./general-form";
import { NestedQuota } from "@revelate/types";
import type { KeyedMutator } from "swr";

export const GeneralPage = ({
  quota,
  mutate,
}: {
  quota?: NestedQuota;
  mutate: KeyedMutator<NestedQuota | null>;
}) => {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">General</h3>
        <p className="text-sm text-muted-foreground">
          Set the basics of your sales quota.
        </p>
      </div>
      <Separator />
      <GeneralForm quota={quota} mutate={mutate} />
    </div>
  );
};
