import {
  getAggregateEarned,
  getCurrencyFormatted,
  groupCommissionsByUserId,
} from "@/lib/app";
import ReactSpeedometer from "react-d3-speedometer";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { DateRange, NestedCommission, Scope } from "@revelate/types";
import { getCommissionsValue } from "@revelate/calc";
import { addDays, differenceInMonths } from "date-fns";

interface ComparisonSpeedometerProps {
  comparisonCommissions: NestedCommission[];
  userCommissions: NestedCommission[];
  scope: Scope | null;
}

// NOTE:
// At the bottom 20° of the scale we want to show 0-25% compressed
// we "simulate" this by choosing a value slightly lower than the scaled bottom
// then "faking" the display for all values under 25%

export function ComparisonSpeedometer({
  comparisonCommissions,
  userCommissions,
  scope,
}: ComparisonSpeedometerProps) {
  const comparisonCommissionsGroupedByUserId = groupCommissionsByUserId(
    getAggregateEarned(comparisonCommissions)
  );

  const highestCommissionToCompare =
    Object.keys(comparisonCommissionsGroupedByUserId)
      .map((k) => getCommissionsValue(comparisonCommissionsGroupedByUserId[k]))
      .toSorted((a, b) => b - a)?.[0] ?? 10_000;

  const { stops: segmentStops, floorValue } =
    createSpeedometerStopsFromTopDealValue(highestCommissionToCompare);

  const userCommissionValue = getCommissionsValue(
    getAggregateEarned(userCommissions)
  );

  const needleValue = scaledValueForSpeedometer(
    userCommissionValue,
    floorValue,
    highestCommissionToCompare
  );
  const displayValue = getCurrencyFormatted(userCommissionValue);

  return (
    <Card className="h-80">
      <CardHeader>
        <CardTitle>Team comparison</CardTitle>
        <CardDescription>
          {scope?.label.split(" ")?.[0]}'s earned commission vs the team
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ReactSpeedometer
          forceRender={true}
          needleColor="#475569"
          textColor="#475569"
          needleHeightRatio={0.6}
          ringWidth={30}
          maxSegmentLabels={7}
          segments={3}
          customSegmentStops={segmentStops}
          segmentColors={[
            "hsl(0 62.8% 30.6%)",
            "hsl(var(--destructive))",
            "hsl(var(--warning))",
            "hsl(var(--green))",
          ]}
          segmentValueFormatter={(v) => segmentLabelFormatter(v, floorValue)}
          labelFontSize={"12px"}
          minValue={segmentStops[0]}
          maxValue={segmentStops[segmentStops.length - 1]}
          value={needleValue}
          currentValueText={displayValue}
        />
      </CardContent>
    </Card>
  );
}

const FLOOR_FACTOR = 0.7;

function createSpeedometerStopsFromTopDealValue(topValue: number): {
  stops: number[];
  floorValue: number;
} {
  const roundingFactor = topValue > 100_000 ? 10_000 : 1_000;

  const valueToNearestRoundedFactor = Math.ceil(topValue / roundingFactor);
  const stopSize = valueToNearestRoundedFactor / 4;
  const scaledStops = [1, 2, 3, 4].map((i) => stopSize * i * roundingFactor);

  const simulatedFloorValue = scaledStops[0] * FLOOR_FACTOR;
  const stops = [simulatedFloorValue, ...scaledStops];
  return { stops, floorValue: simulatedFloorValue };
}

function createSpeedometerStopsFromValueAndTarget(
  userValue: number,
  target: number
): {
  stops: number[];
  floorValue: number;
} {
  const roundingFactor = target > 100_000 ? 10_000 : 1_000;

  const valueToNearestRoundedFactor = Math.ceil(target / roundingFactor);
  const stopSize = valueToNearestRoundedFactor / 4;
  const scaledStops = [1, 2, 3, 4].map((i) => stopSize * i * roundingFactor);

  const simulatedFloorValue = scaledStops[0] * FLOOR_FACTOR;
  const stops = [simulatedFloorValue, ...scaledStops];

  if (userValue > target) {
    stops.push(userValue);
  }

  return { stops, floorValue: simulatedFloorValue };
}

function segmentLabelFormatter(
  value: string,
  floorValue: number,
  stops?: number[] | undefined
) {
  if (value === floorValue.toString()) return "0";
  const numeric = Number.parseFloat(value);

  const label = `${Math.ceil(numeric / 1000)}k`;

  const isNextToLastLabel =
    stops && value === stops[stops.length - 2].toString();
  const percentageDiffBetweenFinal2Values = stops
    ? (stops[stops.length - 1] - stops[stops.length - 2]) /
      stops[stops.length - 1]
    : 1;

  if (isNextToLastLabel && percentageDiffBetweenFinal2Values < 0.05) {
    return "";
  }

  return label;
}

function scaledValueForSpeedometer(
  value: number,
  floorValue: number,
  ceilingValue: number,
  canOverflow = false
) {
  const nextStopAboveFloor = floorValue * (1 / FLOOR_FACTOR);

  if (value > ceilingValue) {
    if (canOverflow) return value;
    return ceilingValue;
  } else if (value >= floorValue) {
    return value;
  } else if (value > 0 && value < floorValue) {
    return (floorValue + nextStopAboveFloor) / 2;
  } else {
    return floorValue;
  }
}

interface TargetSpeedometerProps {
  userCommissions: NestedCommission[];
  usersMonthlyTargets: number[];
  scope: Scope | null;
  dateRange: DateRange;
}

export function TargetSpeedometer({
  userCommissions,
  usersMonthlyTargets,
  scope,
  dateRange,
}: TargetSpeedometerProps) {
  if (!dateRange?.from || !dateRange?.to) {
    return (
      <Card className="h-80">
        <CardHeader>
          <CardTitle>Target attainment</CardTitle>
          <CardDescription>
            {scope?.label.split(" ")?.[0]}'s earned commission vs target
          </CardDescription>
        </CardHeader>
        <CardContent className="relative">
          <CardDescription>Select a date range to view data</CardDescription>
        </CardContent>
      </Card>
    );
  }

  const totalMonthlyTarget = usersMonthlyTargets.reduce((v, a) => v + a, 0);
  const numMonths = differenceInMonths(
    addDays(dateRange.to, 1),
    dateRange.from
  );

  if (numMonths < 1) {
    return (
      <Card className="h-80">
        <CardHeader>
          <CardTitle>Target attainment</CardTitle>
          <CardDescription>
            {scope?.label.split(" ")?.[0]}'s earned commission vs target
          </CardDescription>
        </CardHeader>
        <CardContent className="relative">
          <CardDescription>
            Please select a minimum of 1 month date range to view data
          </CardDescription>
        </CardContent>
      </Card>
    );
  }

  const totalTarget = numMonths * totalMonthlyTarget;

  const userCommissionValue = getCommissionsValue(
    getAggregateEarned(userCommissions)
  );

  const earnedAsPercentageOfTotal = Math.floor(
    (userCommissionValue / totalTarget) * 100
  );

  const { stops: segmentStops, floorValue } =
    createSpeedometerStopsFromValueAndTarget(userCommissionValue, totalTarget);

  const needleValue = scaledValueForSpeedometer(
    userCommissionValue,
    floorValue,
    totalTarget,
    true // canOverflow
  );
  const displayValue = getCurrencyFormatted(userCommissionValue);

  return (
    <Card className="h-80">
      <CardHeader>
        <CardTitle>Target attainment</CardTitle>
        <CardDescription>
          {scope?.label.split(" ")?.[0]}'s earned commission vs target
        </CardDescription>
      </CardHeader>
      <CardContent className="relative">
        <div className="absolute w-[345px] flex flex-row justify-center top-[70px] left-0 z-20">
          <div className="flex flex-col gap-y-0 justify-center items-center text-xs text-[#475569]">
            <p>Target {Math.floor(totalTarget / 1000)}k</p>
            <p>Earned {earnedAsPercentageOfTotal}%</p>
          </div>
        </div>
        <ReactSpeedometer
          forceRender={true}
          needleColor="#475569"
          textColor="#475569"
          needleHeightRatio={0.6}
          ringWidth={30}
          maxSegmentLabels={7}
          segments={3}
          customSegmentStops={segmentStops}
          segmentColors={[
            "hsl(0 62.8% 30.6%)",
            "hsl(var(--destructive))",
            "hsl(var(--warning))",
            "hsl(var(--green))",
            "#90ee90",
          ]}
          segmentValueFormatter={(v) =>
            segmentLabelFormatter(v, floorValue, segmentStops)
          }
          labelFontSize={"12px"}
          minValue={segmentStops[0]}
          maxValue={segmentStops[segmentStops.length - 1]}
          value={needleValue}
          currentValueText={displayValue}
        />
      </CardContent>
    </Card>
  );
}
