import { Link, useLocation, useParams } from "wouter";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string;
    title: string;
  }[];
}

export function SidebarNav({ className, items, ...props }: SidebarNavProps) {
  const [location] = useLocation();

  const { id } = useParams();
  const base = `/quotas/quota/${id}`;
  // const locationWithoutBase = location.replace(base, "");

  return (
    <nav
      className={cn(
        "flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1",
        className
      )}
      {...props}
    >
      {items.map((item, index) => (
        <Link
          key={item.href}
          href={item.href}
          className={() => {
            return cn(
              buttonVariants({ variant: "ghost" }),
              !id && index > 0
                ? "text-muted-foreground hover:bg-transparent hover:text-muted-foreground cursor-default"
                : (item.href === base && location === base) ||
                    (item.href !== base && location.startsWith(item.href))
                  ? "bg-muted hover:bg-muted"
                  : "hover:bg-transparent hover:underline",
              "justify-start"
            );
          }}
        >
          {item.title}
        </Link>
      ))}
    </nav>
  );
}
