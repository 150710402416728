import { Button } from "@/components/ui/button";
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useAppStore } from "@/stores";
import { useAuth } from "@saas-ui/auth";

export const description =
  "A simple login form with email and password. The submit button says 'Sign in'.";

export function MissingUserRole() {
  const { reset } = useAppStore();

  const auth = useAuth();
  const logOut = () => {
    auth.logOut();
    reset();
  };

  return (
    <div className="flex h-screen items-center justify-center">
      <Card className="mx-auto max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl">Missing user role</CardTitle>
          <CardDescription>
            You have successfully signed in but you are missing a user role with
            access privileges. Please contact your administrator to fix this.
            <br />
            <br />
            Once the issue is resolved, you will be need to sign in again.
          </CardDescription>
        </CardHeader>

        <CardFooter>
          <Button onClick={() => logOut()} variant="outline">
            Sign out
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
