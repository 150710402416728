import { useAuth } from "@saas-ui/auth";
import { useEffect } from "react";
import { useLocation } from "wouter";

export const Logout = () => {
  const auth = useAuth();
  const [, setLocation] = useLocation();

  useEffect(() => {
    auth.logOut();
    setLocation("/");
  }, []);

  return <></>;
};
