import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { ToastAction } from "@/components/ui/toast";
import {
  Tooltip,
  TooltipProvider,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAPI } from "@/hooks/useAPI";
import { useJune } from "@/hooks/useJune";
import { useAppStore } from "@/stores";
import { syncAvailable } from "@revelate/utils";
import { RefreshCcw } from "lucide-react";
import { useState } from "react";

export const Synchronize = () => {
  const { syncDealsByDateRange, isLoading } = useAPI();
  const dateRange = useAppStore((state) => state.dateRange);
  const canSync = syncAvailable(dateRange);
  const [open, setOpen] = useState(false);
  const analytics = useJune();

  const handleButton = async () => {
    if (canSync) await syncDealsByDateRange(dateRange);
    analytics?.track("Data synced", { dateRange });
  };

  return (
    <TooltipProvider>
      <Tooltip
        open={open}
        onOpenChange={(open) => setOpen(canSync ? false : open)}
      >
        <TooltipTrigger asChild>
          <div>
            <Button
              onClick={() => handleButton()}
              disabled={isLoading || !canSync}
              style={{ backgroundColor: "#6B6397" }}
            >
              {isLoading ? (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <RefreshCcw className="mr-2 h-4 w-4" />
              )}
              Sync data
            </Button>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          Select a date range of max 1 month to sync.
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
