import * as React from "react";
import {
  CaretSortIcon,
  CheckIcon,
  // PlusCircledIcon,
} from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  // CommandSeparator,
} from "@/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  // DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DomainType, ListItem, Scope } from "@revelate/types/ui";
import { useAppStore } from "@/stores";
import { useLocation, useRoute } from "wouter";
import { scopeTypeAvailableForDomain } from "@/lib/app";
import { getRoute } from "@/lib/routes";
import { abbreviateLongString } from "@revelate/utils";

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface TeamSwitcherProps extends PopoverTriggerProps {
  list: ListItem[];
}

export default function TeamSwitcher({ list, className }: TeamSwitcherProps) {
  const [open, setOpen] = React.useState(false);
  const [showNewTeamDialog, setShowNewTeamDialog] = React.useState(false);
  // const setScope = useAppStore((state) => state.setScope);
  const currentScope = useAppStore((state) => state.scope);
  // const list = useAppStore((state) => state.list);
  const [, setLocation] = useLocation();
  const [, params] = useRoute("/:domain?/:type?/:id?/*?");
  const { domain }: { domain?: string } = params || {};

  return (
    <Dialog open={showNewTeamDialog} onOpenChange={setShowNewTeamDialog}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a team"
            className={cn("w-[200px] justify-between", className)}
          >
            <Avatar className="mr-2 h-5 w-5">
              <AvatarImage
                src={currentScope?.avatar}
                alt={currentScope?.label}
                // className="grayscale"
              />
              <AvatarFallback>{currentScope?.avatar_fallback}</AvatarFallback>
            </Avatar>
            {abbreviateLongString(currentScope?.label || "", 16)}

            <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandList>
              <CommandInput placeholder="Search scope..." />
              <CommandEmpty>No scope found.</CommandEmpty>
              {list &&
                list
                  .filter((item) =>
                    scopeTypeAvailableForDomain(
                      item.type,
                      domain as DomainType | undefined
                    )
                  )
                  .map((item) => (
                    <CommandGroup key={item.label} heading={item.label}>
                      {item.scopes.map((scope: Scope) => (
                        <CommandItem
                          key={scope.value}
                          onSelect={() => {
                            // setScope(scope); // TODO: Remove this?
                            setOpen(false);
                            setLocation(
                              domain
                                ? getRoute(
                                    domain as DomainType,
                                    scope.type,
                                    scope.value
                                  )
                                : getRoute("dashboard", scope.type, scope.value)
                            );
                          }}
                          className="text-sm"
                        >
                          <Avatar className="mr-2 h-5 w-5">
                            <AvatarImage
                              src={scope.avatar}
                              alt={scope.label}
                              // className="grayscale"
                            />
                            <AvatarFallback>
                              {scope.avatar_fallback}
                            </AvatarFallback>
                          </Avatar>
                          {abbreviateLongString(scope.label, 16)}
                          <CheckIcon
                            className={cn(
                              "ml-auto h-4 w-4",
                              currentScope?.value === scope.value &&
                                currentScope?.type === scope.type
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  ))}
            </CommandList>
            {/* <CommandSeparator />
            <CommandList>
              <CommandGroup>
                <DialogTrigger asChild>
                  <CommandItem
                    onSelect={() => {
                      setOpen(false);
                      setShowNewTeamDialog(true);
                    }}
                  >
                    <PlusCircledIcon className="mr-2 h-5 w-5" />
                    Create Team
                  </CommandItem>
                </DialogTrigger>
              </CommandGroup>
            </CommandList> */}
          </Command>
        </PopoverContent>
      </Popover>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create team</DialogTitle>
          <DialogDescription>
            Add a new team to manage products and customers.
          </DialogDescription>
        </DialogHeader>
        <div>
          <div className="space-y-4 py-2 pb-4">
            <div className="space-y-2">
              <Label htmlFor="name">Team name</Label>
              <Input id="name" placeholder="Acme Inc." />
            </div>
            <div className="space-y-2">
              <Label htmlFor="plan">Subscription plan</Label>
              <Select>
                <SelectTrigger>
                  <SelectValue placeholder="Select a plan" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="free">
                    <span className="font-medium">Free</span> -{" "}
                    <span className="text-muted-foreground">
                      Trial for two weeks
                    </span>
                  </SelectItem>
                  <SelectItem value="pro">
                    <span className="font-medium">Pro</span> -{" "}
                    <span className="text-muted-foreground">
                      $9/month per user
                    </span>
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => setShowNewTeamDialog(false)}>
            Cancel
          </Button>
          <Button type="submit" className="bg-[#6B6397]">
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
