// import { Metadata } from "next"
// import Image from "next/image"
// import Link from "next/link"

import { UserAuthForm } from "./components/user-auth-form";
import { LogoInverted } from "@/authentication/components/logo";

export default function AuthenticationPage() {
  return (
    <>
      <div className="container relative hidden h-screen  flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        {/* <Link
          href="/examples/authentication"
          className={cn(
            buttonVariants({ variant: "ghost" }),
            "absolute right-4 top-4 md:right-8 md:top-8"
          )}
        >
          Login
        </Link> */}
        <div className="relative hidden h-full flex-col bg-muted px-8 pt-2 text-white lg:flex dark:border-r">
          <div
            className="absolute inset-0 bg-zinc-900"
            style={{ backgroundColor: "#6B6397" }}
          />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <LogoInverted className="max-w-[180px] pt-6" />
          </div>
          <div className="relative z-20 my-auto w-[550px]">
            <h1 className="scroll-m-20 mb-6 text-5xl font-semibold tracking-tight text-[#ffffff] leading-[60px]">
              Compensation made simple
            </h1>
            <p className="text-lg font-light pt-4 text-[rgb(231,231,232)]">
              Create an account to start automating your sales compensation
              plans today.
            </p>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-3xl font-medium pb-3">Log in to Revelate</h1>
              <p className="text-lg font-light text-muted-foreground">
                Enter your work email below.
              </p>
            </div>
            <UserAuthForm />
            {/* <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <Link
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </Link>
              .
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}
