import { AcceleratorWithConditions, Database } from "@revelate/types";
import { supabaseClient } from "./client";

export const upsertAccelerator = async (
  accelerator: Database["public"]["Tables"]["accelerators"]["Insert"]
) => {
  const query = supabaseClient.from("accelerators").upsert(accelerator);

  const { data, error } = await query
    .select("*, conditions(*)")
    .returns<AcceleratorWithConditions[]>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

export const deleteAccelerator = async (id: number) => {
  const query = supabaseClient.from("accelerators").delete().eq("id", id);
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};
