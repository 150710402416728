import { TODO } from "@revelate/types";

export const arraysIncludeSameValues = (arr1: TODO[], arr2: TODO[]) => {
  return (
    arr1.length === arr2.length && arr1.every((value) => arr2.includes(value))
  );
};

const filterByProperty = (array: string[], data: TODO) =>
  Object.fromEntries(
    Object.entries(data).filter((p) => array.indexOf(p[0]) > -1)
  );

export const filterProperties = (propertiesToInclude: string[], data: TODO) => {
  return filterByProperty(propertiesToInclude, data);
};

export const sortByStringKey = (key: string) => (a: TODO, b: TODO) => {
  return ("" + a[key]).localeCompare(b[key] || "");
};
