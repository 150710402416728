import { AppRole, TODO } from "@revelate/types";
import { Session } from "@supabase/supabase-js";
import { jwtDecode } from "jwt-decode";

export const getUserRoleFromSession = (
  session: Session | null
): AppRole | undefined => {
  if (session) {
    const jwt = jwtDecode(session.access_token) as TODO;
    const { user_role } = jwt || {};
    return user_role;
  }
  return undefined;
};
