import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { KeyDeal, NestedCommission, Scope } from "@revelate/types";
import {
  getMonthNameFromInteger,
  getNextQuarterDateRange,
} from "@revelate/utils";
import { getQuarter } from "date-fns";
import { KeyDealsDataTable, keyDealsColumns } from "./data-table";

interface KeyDealsMonthProps {
  keyDeals: KeyDeal[];
  scope: Scope | null;
}

export function KeyDealsMonth({ keyDeals, scope }: KeyDealsMonthProps) {
  const today = new Date();
  const monthLabel = getMonthNameFromInteger(today.getMonth(), true);
  return (
    <Card>
      <CardHeader>
        <CardTitle>Key deals of the month</CardTitle>
        <CardDescription>
          {scope?.label.split(" ")[0]}'s top open deals for {monthLabel}{" "}
          {today.getFullYear()}
        </CardDescription>
      </CardHeader>
      <CardContent>
        {keyDeals.length > 0 ? (
          <KeyDealsDataTable
            columns={keyDealsColumns}
            keyDeals={keyDeals}
            scope={scope}
          />
        ) : (
          <CardDescription>No open deals in {monthLabel}</CardDescription>
        )}
      </CardContent>
    </Card>
  );
}

interface KeyDealsNextQuarterProps {
  keyDeals: KeyDeal[];
  scope: Scope | null;
}

export function KeyDealsNextQuarter({
  keyDeals,
  scope,
}: KeyDealsNextQuarterProps) {
  const nextQuarter = getNextQuarterDateRange();
  const quarterLabel =
    "Q" + getQuarter(nextQuarter.from) + " " + nextQuarter.from.getFullYear();
  return (
    <Card>
      <CardHeader>
        <CardTitle>Key deals for success next quarter</CardTitle>
        <CardDescription>
          {scope?.label.split(" ")[0]}'s top open deals for upcoming quarter{" "}
          {quarterLabel}
        </CardDescription>
      </CardHeader>
      <CardContent>
        {keyDeals.length > 0 ? (
          <KeyDealsDataTable
            columns={keyDealsColumns}
            keyDeals={keyDeals}
            scope={scope}
          />
        ) : (
          <CardDescription>No open deals in {quarterLabel}</CardDescription>
        )}
      </CardContent>
    </Card>
  );
}
