import * as React from "react";
import { CheckIcon, Circle, HelpCircle, LucideIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { TableType, getAll } from "@/lib/supabase";
import useSWR from "swr";
import { useAppStore } from "@/stores";
import { getQuotaLabel } from "@/lib/app";

export type Criteria = {
  key: KeyType;
  label: string;
  type: string; // "number" | "percentage"
  icon: LucideIcon;
  description: string;
  quota_id?: number;
};

export const criterias: Criteria[] = [
  // {
  //   key: {
  //     key_name: "attainment_period",
  //   },
  //   type: "percentage",
  //   label: "Target attainment for period",
  //   icon: HelpCircle,
  // },
  // {
  //   key: {
  //     key_name: "attainment_ytd",
  //   },
  //   type: "percentage",
  //   label: "Target attainment year to date",
  //   icon: HelpCircle,
  // },
  {
    key: {
      key_name: "deals_contract_length_months",
    },
    type: "number",
    label: "Deal contract length",
    description: "Contract length in months",
    icon: HelpCircle,
  },
  {
    key: {
      key_name: "deals_invoice_length_months",
    },
    type: "number",
    label: "Deal invoice period",
    description: "Invoice period in months",
    icon: Circle,
  },
  {
    key: {
      key_name: "deals_size_value",
    },
    type: "number",
    label: "Deal size",
    description: "Value of deal in SEK",
    icon: Circle,
  },
  {
    key: {
      key_name: "deals_size_arr_value",
    },
    type: "number",
    label: "Deal size ARR",
    description: "Annual recurring value of deal in SEK",
    icon: Circle,
  },
  {
    key: {
      key_name: "deals_customer_deal_count",
    },
    type: "number",
    label: "Deal count",
    description: "Number of deals for the same customer",
    icon: Circle,
  },
];

type KeyType = {
  key_name?: string;
  quota_id?: number;
};

export function CriteriaCombobox({
  value,
  onChange,
}: {
  value: KeyType;
  onChange: (value: KeyType) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [selectedCriteria, setSelectedCriteria] = React.useState<
    Criteria | undefined
  >(undefined);
  const currentUser = useAppStore((state) => state.currentUser);

  const [allCriterias, setAllCriterias] = React.useState<Criteria[]>([]);

  const {
    data: quotas,
    error,
    isLoading,
  } = useSWR(
    [
      "quotas" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId)
  );

  React.useEffect(() => {
    if (!isLoading && quotas) {
      // Combine the criterias with the quotas
      const quotasAsCriterias = quotas?.map((quota) => {
        return {
          quota_id: quota.id,
          key: {
            key_name: `quota_id_${quota.id}`,
            quota_id: quota.id,
          },
          label: `${getQuotaLabel(quota)}`,
          description: `Attained % of sales quota`,
          type: "percentage",
          icon: HelpCircle,
        };
      });
      const allCriterias: Criteria[] = [...quotasAsCriterias, ...criterias];
      setAllCriterias(allCriterias);
      const selectedCriteria = allCriterias.find(
        (c) => c.key.key_name === value.key_name
      );
      setSelectedCriteria(selectedCriteria);
    }
  }, [quotas, isLoading]);

  const handleSelect = (label: string) => {
    const selectedCriteria = allCriterias.find((c) => c.label === label);
    setSelectedCriteria(selectedCriteria);
    setOpen(false);
    onChange({
      key_name: selectedCriteria?.key.key_name || "",
      quota_id: selectedCriteria?.quota_id
        ? selectedCriteria?.quota_id
        : undefined,
    });
  };

  if (error)
    return (
      <div className="text-center text-muted-foreground">
        Error loading data
      </div>
    );

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            className="w-[250px] justify-start"
          >
            {selectedCriteria ? (
              <>
                {/* <selectedCriteria.icon className="mr-2 h-4 w-4 shrink-0" /> */}
                {selectedCriteria.label}
              </>
            ) : (
              <>+ Set criteria</>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0 w-[350px]" side="right" align="start">
          <Command>
            <CommandInput placeholder="Search criteria..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {allCriterias.map((criteria) => (
                  <CommandItem
                    key={criteria.key.key_name}
                    value={criteria.label}
                    onSelect={(label) => handleSelect(label)}
                  >
                    <div className="flex items-start gap-3 text-muted-foreground">
                      <div className="grid gap-0.5">
                        <p>
                          <span className="font-medium text-foreground">
                            {criteria.label}
                          </span>
                        </p>
                        <p className="text-xs" data-description>
                          {criteria.description}
                        </p>
                      </div>
                    </div>
                    <CheckIcon
                      className={cn(
                        "ml-auto my-auto h-6 w-6",
                        criteria.key.key_name === selectedCriteria?.key.key_name
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
