import { integrations } from "@/constants/integrations";
import {
  CommissionBasedOn,
  NestedPlan,
  NestedToken,
  RevelateDealType,
  TokenProvider,
} from "@revelate/types";
import { capitalizeFirstLetter } from "@revelate/utils";

export type CommissionBasisButtons = {
  label: string;
  value: CommissionBasedOn;
};

export type DealTypeButtons = {
  value: RevelateDealType;
  label: string;
};

export const dealTypesRadioButtons: DealTypeButtons[] = [
  {
    value: "new_business",
    label: "New",
  },
  {
    value: "existing_business",
    label: "Existing",
  },
];

export const getProviderLabel = (provider: TokenProvider) => {
  return (
    integrations?.find((integration) => integration.id === provider)?.name ||
    capitalizeFirstLetter(provider)
  );
};

export const getDealProvidersMultiSelectOptions = (tokens?: NestedToken[]) => {
  // TODO: Make sure currently selected providers are returned here and displayed in multi select even if we remove a token
  return tokens && tokens.length > 0
    ? tokens?.map((token) => {
        return {
          label: getProviderLabel(token.provider),
          value: token.provider,
          icon: undefined,
        };
      })
    : [];
};

export const commissionBasisButtons: CommissionBasisButtons[] = [
  {
    label: "Business of the current period",
    value: "deal_value",
  },
  {
    label: "ARR of business of the current period",
    value: "deal_value_arr",
  },
  {
    label: `Onboarding fees of business of the current period`,
    value: "onboarding_value",
  },
  {
    label: `Fixed value`,
    value: "fixed_value",
  },
  // {
  //   label: `Deals exceeding sales target of the ${plan?.time_period}`,
  //   labelShort: `Deals exceeding sales target`,
  //   value: "deal_value_above_target",
  // },
  // {
  //   label: `${capitalizeFirstLetter(dealTypeLabels)} for the ${
  //     plan?.time_period
  //   } matching
  //   criteria`,
  //   value: "individual_deal_value",
  // },
];

export const criteriaRadioButtons = [
  {
    label: "Always",
    value: "always",
  },
  {
    label: "Only if ALL criteria is met",
    value: "all_criteria_met",
  },
  {
    label: "Only if ANY criteria is met",
    value: "any_criteria_met",
  },
];

export const getCommissionDealTypeLabels = (
  deal_types?: RevelateDealType[]
) => {
  const dealTypes = dealTypesRadioButtons
    ?.filter((item) => deal_types?.includes(item.value))
    ?.map((item) => item.label.toLowerCase());
  return dealTypes.length === dealTypesRadioButtons.length
    ? "all business"
    : `${dealTypes?.join(" and ")} business`;
};

export const getCommissionBasisLabelForValue = (
  plan: NestedPlan | null,
  b: CommissionBasisButtons
) => {
  const dealTypeLabels = getCommissionDealTypeLabels(plan?.deal_types);
  switch (b.value) {
    case "deal_value":
      return `${capitalizeFirstLetter(dealTypeLabels)} of the ${
        plan?.time_period
      }`;
    case "deal_value_arr":
      return `ARR of ${dealTypeLabels.toLowerCase()} of the ${
        plan?.time_period
      }`;
    case "onboarding_value":
      return `Onboarding fees from ${dealTypeLabels.toLowerCase()} of the ${
        plan?.time_period
      }`;
    case "fixed_value":
      return `Fixed value`;
    default:
      return b.label;
  }
};

export const getCommissionBasisRadioButtons = (plan?: NestedPlan | null) => {
  return commissionBasisButtons.map((b) => {
    return {
      ...b,
      label: plan ? getCommissionBasisLabelForValue(plan, b) : b.label,
    };
  });
};

export const getCommissionBasisLabel = (
  plan: NestedPlan | null,
  commission_based_on_value: CommissionBasedOn
) => {
  const basisLabels = getCommissionBasisRadioButtons(plan);
  return basisLabels.find((item) => item.value === commission_based_on_value)
    ?.label;
};

export const getCommissionBasisValues = () => {
  return commissionBasisButtons.map((item) => item.value);
};
