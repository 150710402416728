import { Database } from "@revelate/types";
import { supabaseClient } from "./client";

export const getConditionsForAccelerator = async (
  acceleratorId: number
): Promise<Database["public"]["Tables"]["conditions"]["Row"][]> => {
  const { data, error } = await supabaseClient
    .from("conditions")
    .select("*")
    .eq("accelerator_id", acceleratorId)
    .returns<Database["public"]["Tables"]["conditions"]["Row"][]>();
  if (error) {
    throw error;
  }
  return data || [];
};

export const deleteConditionsForAccelerator = async (
  acceleratorId: number
): Promise<void> => {
  const { error } = await supabaseClient
    .from("conditions")
    .delete()
    .eq("accelerator_id", acceleratorId);
  if (error) {
    throw error;
  }
};

export const insertConditionsForAccelerator = async (
  conditions: Database["public"]["Tables"]["conditions"]["Insert"][]
): Promise<Database["public"]["Tables"]["conditions"]["Row"][]> => {
  const { data, error } = await supabaseClient
    .from("conditions")
    .insert(conditions)
    .select("*")
    .returns<Database["public"]["Tables"]["conditions"]["Row"][]>();
  if (error) {
    throw error;
  }
  return data || [];
};

export const replaceConditionsForAccelerator = async (
  acceleratorId: number,
  conditions: Database["public"]["Tables"]["conditions"]["Insert"][]
) => {
  if (conditions.length === 0) return [];
  await deleteConditionsForAccelerator(acceleratorId);
  const data = await insertConditionsForAccelerator(conditions);
  return data;
};
