import * as React from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import {
  format,
  addQuarters,
  addMonths,
  subMonths,
  endOfMonth,
  startOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  subYears,
  subQuarters,
  addYears,
} from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useAppStore } from "@/stores";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export function CalendarDateRangePicker({
  className,
}: React.HTMLAttributes<HTMLDivElement>) {
  const dateRange = useAppStore((state) => state.dateRange);
  const setDateRange = useAppStore((state) => state.setDateRange);
  const dateRangeValue = useAppStore((state) => state.dateRangeValue);
  const setDateRangeValue = useAppStore((state) => state.setDateRangeValue);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <div className="flex space-x-2">
          <Select
            value={dateRangeValue}
            defaultValue={dateRangeValue}
            onValueChange={setDateRangeValue}
          >
            <SelectTrigger>
              <SelectValue placeholder="Custom range" />
            </SelectTrigger>
            <SelectContent position="popper">
              <SelectGroup>
                <SelectLabel>Month</SelectLabel>

                <SelectItem
                  value={`${startOfMonth(
                    addMonths(new Date(), 1)
                  ).toISOString()},${endOfMonth(
                    addMonths(new Date(), 1)
                  ).toISOString()}`}
                >
                  Next month
                </SelectItem>
                <SelectItem
                  value={`${startOfMonth(
                    new Date()
                  ).toISOString()},${endOfMonth(new Date()).toISOString()}`}
                >
                  Current month
                </SelectItem>

                <SelectItem
                  value={`${startOfMonth(
                    subMonths(new Date(), 1)
                  ).toISOString()},${endOfMonth(
                    subMonths(new Date(), 1)
                  ).toISOString()}`}
                >
                  Last month
                </SelectItem>
              </SelectGroup>
              <SelectGroup>
                <SelectLabel>Quarter</SelectLabel>

                <SelectItem
                  value={`${startOfQuarter(
                    addQuarters(new Date(), 1)
                  ).toISOString()},${endOfQuarter(
                    addQuarters(new Date(), 1)
                  ).toISOString()}`}
                >
                  Next quarter
                </SelectItem>
                <SelectItem
                  value={`${startOfQuarter(
                    new Date()
                  ).toISOString()},${endOfQuarter(new Date()).toISOString()}`}
                >
                  Current quarter
                </SelectItem>

                <SelectItem
                  value={`${startOfQuarter(
                    subQuarters(new Date(), 1)
                  ).toISOString()},${endOfQuarter(
                    subQuarters(new Date(), 1)
                  ).toISOString()}`}
                >
                  Last quarter
                </SelectItem>
              </SelectGroup>
              <SelectGroup>
                <SelectLabel>Year</SelectLabel>
                <SelectItem
                  value={`${startOfYear(
                    addYears(new Date(), 1)
                  ).toISOString()},${endOfYear(
                    addYears(new Date(), 1)
                  ).toISOString()}`}
                >
                  Next year
                </SelectItem>
                <SelectItem
                  value={`${startOfYear(new Date()).toISOString()},${endOfYear(
                    new Date()
                  ).toISOString()}`}
                >
                  This year
                </SelectItem>
                <SelectItem
                  value={`${startOfYear(
                    subYears(new Date(), 1)
                  ).toISOString()},${endOfYear(
                    subYears(new Date(), 1)
                  ).toISOString()}`}
                >
                  Last year
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>

          <PopoverTrigger asChild>
            <Button
              id="date"
              variant={"outline"}
              className={cn(
                "w-[280px] justify-start text-left font-normal",
                !dateRange && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {dateRange?.from ? (
                dateRange?.to ? (
                  <>
                    {format(dateRange.from, "LLL dd, y")} -{" "}
                    {format(dateRange.to, "LLL dd, y")}
                  </>
                ) : (
                  format(dateRange.from, "LLL dd, y")
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </PopoverTrigger>
        </div>

        <PopoverContent className="w-auto p-0" align="end">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={setDateRange}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
