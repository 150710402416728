import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { DateRange } from "react-day-picker";

export const getTimePeriodFromDateRange = (
  dateRange?: DateRange,
  includePrefix = false
) => {
  if (!dateRange || (dateRange && !dateRange.from && !dateRange.to)) {
    return "";
  }
  const from = dateRange.from
    ? format(dateRange.from, "LLL dd, yyyy", { locale: enUS })
    : null;
  const to = dateRange.to
    ? format(dateRange.to, "LLL dd, yyyy", { locale: enUS })
    : null;

  if (!from && !to) {
    return "";
  }
  if (from && !to) {
    return includePrefix ? `From ${from}` : from;
  }
  if (!from && to) {
    return includePrefix ? `To ${to}` : to;
  }
  return `${from} — ${to}`;
};
