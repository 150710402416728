import { NestedCompany, SignedInUser } from "@revelate/types";
import { supabaseClient } from "./client";
import { getTableColums } from "./shared";

// export const getCompany = async (companyId: string) => {
//   const { data, error } = await supabaseClient
//     .from("companies")
//     .select(getTableColums("companies"))
//     .eq("id", Number(companyId))
//     // .not("users.provider_id", "is", null)
//     .returns<NestedCompany[]>()
//     .limit(1)
//     .maybeSingle();
//   if (error) {
//     throw error;
//   }
//   return data;
// };

export const getCompanies = async (user: SignedInUser) => {
  if (!user) return [];
  const { data, error } = await supabaseClient
    .from("companies")
    .select("name, id")
    .order("id");
  // .returns<Database["public"]["Tables"]["companies"]["Row"][]>();
  if (error) {
    throw error;
  }
  return data;
};

export const updateCompanyCurrency = async (
  companyId: number,
  currencyId: number
) => {
  const { data, error } = await supabaseClient
    .from("companies")
    .update({
      default_currency_id: currencyId,
    })
    .eq("id", companyId)
    .select(getTableColums("companies"))
    .returns<NestedCompany[]>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};
