import { useAppStore } from "@/stores";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  Legend,
  Cell,
  ReferenceLine,
} from "recharts";
import {
  combineEarnedAndProjectedCommissionMonths,
  getCurrencyFormatted,
  getMonthsList,
  groupCommissionsByDealName,
} from "@/lib/app";
import {
  CommissionMonth,
  CommissionMonthWithProjected,
  NestedCommission,
  TODO,
} from "@revelate/types";
import useSWR from "swr";
import { TableType, get } from "@/lib/supabase";
import { Loader } from "@/components/Loader";
import { compareYearMonth } from "@revelate/utils";

type CommissionMonthGraph = CommissionMonthWithProjected & {
  earned: number;
  projectionDifference: number;
};

function getCommissionsByMonthForGraph(
  commissionsByMonth: CommissionMonthWithProjected[]
): CommissionMonthGraph[] {
  const today = new Date();
  const thisYear = today.getFullYear();
  const thisMonth = today.getMonth() + 1;

  return commissionsByMonth.map((cm) => {
    const earned = cm.value;
    let projectionDifference = cm.projectedValue - cm.value;

    // Only show forecast from current month onwards, and only positive values
    const commissionMonthTimeFrame = compareYearMonth(
      { year: cm.year, month: cm.month },
      { year: thisYear, month: thisMonth }
    );

    if (
      commissionMonthTimeFrame < 0 ||
      (commissionMonthTimeFrame === 0 && projectionDifference < 0)
    ) {
      projectionDifference = 0;
    }

    return {
      ...cm,
      earned,
      projectionDifference,
    };
  });
}

const CustomTooltip = ({ active, payload, label }: TODO) => {
  const currentUser = useAppStore((state) => state.currentUser);
  const { data: company, isLoading } = useSWR(
    [
      "companies" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => get(table, companyId)
  );
  const scope = useAppStore((state) => state.scope);

  if (isLoading) return <Loader />;

  if (active && payload && payload.length) {
    const earnedLine = `${getCurrencyFormatted(
      payload?.[0]?.payload?.value ?? 0
    )} earned`;
    const projectedOnlyLine = `${getCurrencyFormatted(
      payload?.[0]?.payload?.projectedValue ?? 0
    )} forecasted`;
    const projectionDifferenceLine =
      payload?.[0]?.payload?.projectionDifference > 0
        ? `+${getCurrencyFormatted(payload?.[0]?.payload?.projectionDifference ?? 0)} forecasted`
        : "";
    const scopeLabel = ` • ${scope?.label || company?.name || ""}`;

    const hasValue =
      payload?.[0]?.payload?.value !== undefined &&
      payload?.[0]?.payload?.value !== 0;
    const hasProjectionDifference =
      payload?.[0]?.payload?.projectionDifference > 0;

    let textLines = [];

    if (hasValue && hasProjectionDifference) {
      textLines = [earnedLine, projectionDifferenceLine];
    } else if (hasProjectionDifference) {
      textLines = [projectedOnlyLine];
    } else {
      textLines = [earnedLine];
    }

    return (
      <Card className="max-w-64">
        <CardHeader>
          <CardTitle className="text-md">
            {label}
            {scopeLabel}
          </CardTitle>
          <div className="flex flex-col gap-y-0">
            {textLines.map(
              (line) =>
                line !== "" && (
                  <CardDescription className="text-sm">{line}</CardDescription>
                )
            )}
          </div>
        </CardHeader>
      </Card>
    );
  }

  return null;
};

interface OverviewBarChartProps {
  unapprovedCommissions: NestedCommission[];
  projectedCommissions?: NestedCommission[];
}

export function OverviewBarChart({
  unapprovedCommissions,
  projectedCommissions,
}: OverviewBarChartProps) {
  const dateRange = useAppStore((state) => state.dateRange);
  const unapprovedCommissionsByMonth: CommissionMonth[] = getMonthsList(
    unapprovedCommissions,
    dateRange
  );

  let commissionsByMonth: CommissionMonthWithProjected[] = [];

  if (projectedCommissions) {
    const projectedCommissionsByMonth: CommissionMonth[] = getMonthsList(
      projectedCommissions,
      dateRange
    );
    commissionsByMonth = combineEarnedAndProjectedCommissionMonths(
      unapprovedCommissionsByMonth,
      projectedCommissionsByMonth
    );
  } else {
    commissionsByMonth = unapprovedCommissionsByMonth.map((cm) => ({
      ...cm,
      projectedValue: 0,
      projectionDifference: 0,
      projectionDifferenceAbsolute: 0,
    }));
  }

  const commissionsByMonthForGraph =
    getCommissionsByMonthForGraph(commissionsByMonth);

  const CustomLegend = () => {
    return (
      <div className="flex flex-row justify-center items-center gap-x-3">
        <div className="flex flex-row justify-center items-center gap-x-1">
          <div className="w-3 h-3 bg-[#6B6397]" />
          <p className="text-[#6B6397]">Earned</p>
        </div>
        <div className="flex flex-row justify-center items-center gap-x-1">
          <div className="w-3 h-3 bg-[#aa9bea]" />
          <p className="text-[#aa9bea]">Forecasted</p>
        </div>
      </div>
    );
  };
  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart data={commissionsByMonthForGraph} stackOffset="sign">
        <XAxis
          dataKey="label"
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => `${value / 1000} kkr`}
        />
        <ReferenceLine y={0} color="black" />
        <RechartsTooltip
          cursor={{ fill: "#fff" }}
          content={<CustomTooltip />}
        />
        <Legend content={<CustomLegend />} />

        {/* <Bar */}
        {/*   dataKey="lowerForecast" */}
        {/*   fill="#aa9bea" */}
        {/*   className="fill-[#aa9bea]" */}
        {/*   stackId="b" */}
        {/* > */}
        {/*   {commissionsByMonthForGraph.map((_, index) => { */}
        {/*     return ( */}
        {/*       <Cell */}
        {/*         key={`cell-${index}`} */}
        {/*         // @ts-expect-error <Type definition is wrong> */}
        {/*         radius={[0, 0, 0, 0]} */}
        {/*       /> */}
        {/*     ); */}
        {/*   })} */}
        {/* </Bar> */}

        <Bar
          dataKey="earned"
          fill="#6B6397"
          className="fill-[#6B6397]"
          stackId="b"
        >
          {commissionsByMonthForGraph.map((entry, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                // @ts-expect-error <Type definition is wrong>
                radius={
                  entry.projectionDifference <= 0 ? [2, 2, 0, 0] : [0, 0, 0, 0]
                }
              />
            );
          })}
        </Bar>

        <Bar
          dataKey="projectionDifference"
          fill="#aa9bea"
          className="fill-[#aa9bea]"
          stackId="b"
        >
          {commissionsByMonthForGraph.map((_, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                // @ts-expect-error <Type definition is wrong>
                radius={[2, 2, 0, 0]}
              />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

function hasOppositeSigns(a: number, b: number) {
  return Math.sign(a * b) === -1;
}
