import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { OverviewBarChart } from "@/dashboard/components/overview";
import { consolidateCommissions, groupCommissionsByDealName } from "@/lib/app";
import { useAppStore } from "@/stores";
import { Route, Switch, useLocation } from "wouter";
import { Scope } from "./components/scope";
import { RecentDealsCard, RecentSalesCard } from "./cards";
import {
  calculateCommissionsForUsers,
  getDealsForDateRange,
  getKeyDeals,
  getUsersForScope,
  getStalledDeals,
} from "@revelate/calc";
import useSWR from "swr";
import { getCommissions } from "@/lib/supabase/commissions";
import { LineChart } from "lucide-react";
import { Button } from "@/components/ui/button";
import { getRoute } from "@/lib/routes";
import { TableType, get, getAll } from "@/lib/supabase";
import { LoadingError } from "@/components/LoadingError";
import { NestedDeal } from "@revelate/types";
import { Loader } from "@/components/Loader";
import { CommissionKeyValues } from "./components/commission-key-values";
import { DealKeyValues } from "./components/deal-key-values";
import { KeyDealsMonth, KeyDealsNextQuarter } from "./components/key-deals";
import { StalledDeals } from "./components/stalled-deals";
import {
  getCurrentMonthDateRange,
  getNextQuarterDateRange,
} from "@revelate/utils";
import {
  ComparisonSpeedometer,
  TargetSpeedometer,
} from "./components/target-speedometer";

export default function DashboardPage() {
  const { currentUser, scope, dateRange } = useAppStore((state) => state);
  const { data: company, isLoading: companyIsLoading } = useSWR(
    [
      "companies" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => get(table, companyId)
  );

  const {
    data: deals,
    error,
    isLoading,
  } = useSWR(
    [
      "deals" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]): Promise<NestedDeal[]> => getAll(table, companyId)
  );

  // -----------
  // Scoped data
  // -----------

  const scopedDeals = getDealsForDateRange({
    deals: deals || [],
    dateRange,
    scope,
  });

  const stalledDeals = getStalledDeals(scopedDeals || []);

  const scopedUsers = company
    ? getUsersForScope(company, scope)?.filter(
        (u) => u.plans && u.plans.length > 0
      )
    : [];
  const scopedCommissions = calculateCommissionsForUsers(
    dateRange,
    company,
    scopedUsers
  );

  const scopedProjectedCommissions = calculateCommissionsForUsers(
    dateRange,
    company,
    scopedUsers,
    true //includeProjections
  );

  const scopedUserMonthlyCommissionTargets = scopedUsers
    ?.map((user) => user.commission_target)
    .filter((t) => t !== null);

  const { data: persistedCommissions } = useSWR(
    scopedUsers?.map((u) => u.id),
    getCommissions
  );
  const commissions = consolidateCommissions(
    scopedCommissions,
    persistedCommissions
  );
  const projectedCommissions = consolidateCommissions(
    scopedProjectedCommissions,
    persistedCommissions
  );

  const projectedCommissionsThisMonth = consolidateCommissions(
    calculateCommissionsForUsers(
      getCurrentMonthDateRange(),
      company,
      scopedUsers,
      true //includeProjections
    ),
    persistedCommissions
  );

  const projectedCommissionsNextQuarter = consolidateCommissions(
    calculateCommissionsForUsers(
      getNextQuarterDateRange(),
      company,
      scopedUsers,
      true //includeProjections
    ),
    persistedCommissions
  );

  const keyDealsThisMonth = getKeyDeals(
    groupCommissionsByDealName(projectedCommissionsThisMonth || [])
  );
  const keyDealsNextQuarter = getKeyDeals(
    groupCommissionsByDealName(projectedCommissionsNextQuarter || [])
  );

  // --------------
  // All users data
  // --------------
  const allUsersCommissionsThisPeriod = consolidateCommissions(
    calculateCommissionsForUsers(dateRange, company, company?.users || []),
    persistedCommissions
  );

  const [, setLocation] = useLocation();

  if (companyIsLoading || isLoading || !deals) {
    return <Loader />;
  }
  if (error) {
    return <LoadingError />;
  }

  return (
    <>
      <div className="flex-col md:flex">
        <div className="flex-1 space-y-4 py-0">
          <div className="flex items-center justify-between space-y-2">
            <Scope />
            <div className="flex items-center gap-4">
              {scope && (
                <Button
                  size="lg"
                  onClick={() =>
                    setLocation(getRoute("reports", scope?.type, scope?.value))
                  }
                >
                  <LineChart className="h-4 w-4 mr-2" />
                  View Report
                </Button>
              )}
            </div>
          </div>
          <Tabs defaultValue="commissions" className="space-y-4">
            <TabsList>
              <TabsTrigger value="commissions">Commissions</TabsTrigger>
              <TabsTrigger value="deals">Deals</TabsTrigger>
            </TabsList>
            <TabsContent value="commissions" className="space-y-4">
              <CommissionKeyValues
                commissions={commissions}
                projectedCommissions={projectedCommissions}
              />
            </TabsContent>
            <TabsContent value="deals" className="space-y-4">
              <DealKeyValues company={company} scopedDeals={scopedDeals} />
            </TabsContent>

            <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-6">
              <div className="col-span-3 flex flex-col gap-4">
                <TabsContent value="commissions">
                  <Card>
                    <CardHeader>
                      <CardTitle>Commission by month</CardTitle>
                      <CardDescription>
                        The commission earned per month
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="pl-2">
                      <OverviewBarChart
                        unapprovedCommissions={commissions}
                        projectedCommissions={projectedCommissions}
                      />
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="deals">
                  <div className="flex flex-col gap-4">
                    <KeyDealsMonth keyDeals={keyDealsThisMonth} scope={scope} />
                    <KeyDealsNextQuarter
                      keyDeals={keyDealsNextQuarter}
                      scope={scope}
                    />
                    <StalledDeals deals={stalledDeals} scope={scope} />
                  </div>
                </TabsContent>
              </div>
              <div className="col-span-3 flex flex-col gap-4">
                <Switch>
                  <Route path="/dashboard/user/:id">
                    <TabsContent value="commissions">
                      {company?.id === 1 || company?.id === 3 ? (
                        scopedUserMonthlyCommissionTargets.length > 0 && (
                          <TargetSpeedometer
                            userCommissions={commissions}
                            usersMonthlyTargets={
                              scopedUserMonthlyCommissionTargets
                            }
                            scope={scope}
                            dateRange={dateRange}
                          />
                        )
                      ) : (
                        <ComparisonSpeedometer
                          comparisonCommissions={allUsersCommissionsThisPeriod}
                          userCommissions={commissions}
                          scope={scope}
                        />
                      )}
                    </TabsContent>
                    <RecentDealsCard company={company} deals={deals} />
                  </Route>
                  <Route path="/dashboard/team/:id">
                    <TabsContent value="commissions">
                      <ComparisonSpeedometer
                        comparisonCommissions={allUsersCommissionsThisPeriod}
                        userCommissions={commissions}
                        scope={scope}
                      />
                    </TabsContent>
                    <RecentDealsCard company={company} deals={deals} />
                  </Route>
                  <Route>
                    <RecentSalesCard
                      company={company}
                      scopedUsers={scopedUsers}
                      scopedCommissions={commissions}
                    />
                  </Route>
                </Switch>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </>
  );
}
