import { Button } from "@/components/ui/button";
import { ConfirmDeletionDialog } from "@/components/ConfirmDeletionDialog";
import {
  getApproved,
  getPaid,
  getUnapproved,
  isApproved,
  isUnapproved,
} from "@/lib/app";
import { getCommissionsValue } from "@revelate/calc";
import { Check, PiggyBank, Trash2Icon } from "lucide-react";
import { useAppStore } from "@/stores";
import { CommissionStatus, NestedCommission } from "@revelate/types";
import { Loader } from "@/components/Loader";

interface ApproveButtonProps {
  commissions: NestedCommission[];
  updateCommissions: (
    previousStatus: CommissionStatus,
    newStatus: CommissionStatus,
    filterByCommission?: NestedCommission
  ) => void;
  loading?: boolean;
}

export const ApproveButton = ({
  commissions,
  updateCommissions,
  loading = false,
}: ApproveButtonProps) => {
  const currentUser = useAppStore((state) => state.currentUser);

  return (
    <>
      {" "}
      {currentUser?.role === "super_admin" || currentUser?.role === "admin" ? (
        isUnapproved(commissions) ? (
          <Button
            onClick={() => updateCommissions("earned", "approved")}
            size="lg"
            variant="default"
            className="bg-[#6B6397]"
            disabled={loading}
          >
            {loading ? (
              <Loader size={20} stroke="white" className="mr-2" />
            ) : (
              <Check className="h-5 w-5 mr-2" />
            )}
            Approve all ({getUnapproved(commissions).length})
          </Button>
        ) : isApproved(commissions) ? (
          <Button
            onClick={() => updateCommissions("approved", "paid")}
            size="lg"
            variant="default"
            className="bg-[#6B6397]"
          >
            <PiggyBank className="h-5 w-5 mr-2" />
            Mark as paid ({getApproved(commissions).length})
          </Button>
        ) : getCommissionsValue(commissions) > 0 ? (
          <ConfirmDeletionDialog
            key={`delete-commissions`}
            title={`Are you sure you want to revert all commissions for this period?`}
            description="All commissions for this period will be set to the default state."
            deleteId={"all"}
            deleteOperation={async () => {
              updateCommissions("paid", "earned");
            }}
          >
            <Button size="lg" variant="outline">
              <Trash2Icon className="h-5 w-5 mr-2" />
              Undo ({getPaid(commissions).length})
            </Button>
          </ConfirmDeletionDialog>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};
