import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { NestedDeal, Scope } from "@revelate/types";
import { NestedDealsMiniDataTable, stalledDealsColumns } from "./data-table";
import { NestedDealStalled } from "@revelate/calc";

interface StalledDealsProps {
  deals: NestedDealStalled[];
  scope: Scope | null;
}

export function StalledDeals({ deals, scope }: StalledDealsProps) {
  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>Red flagged deals</CardTitle>
          <CardDescription>
            Deals for this period which are not moving forward
          </CardDescription>
        </CardHeader>
        <CardContent>
          {deals.length > 0 ? (
            <NestedDealsMiniDataTable
              deals={deals}
              columns={stalledDealsColumns}
              scope={scope}
            />
          ) : (
            <CardDescription>No deals found</CardDescription>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
