// import {
//   HoverCard,
//   HoverCardContent,
//   HoverCardTrigger,
// } from "@/components/ui/hover-card";
import { Slider } from "@/components/ui/slider";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { CustomFormItemProps } from "../accelerators-form";
import { Input } from "@/components/ui/input";

export function CommissionSelector({ value, onChange }: CustomFormItemProps) {
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    onChange(inputValue);
  };

  return (
    <FormItem>
      <div className="grid gap-2 pt-2">
        {/* <HoverCard openDelay={200}>
        <HoverCardTrigger asChild> */}
        <div className="grid gap-4">
          <div className="flex items-center justify-between">
            <FormLabel className="text-xl" htmlFor="temperature">
              <div className="flex flex-row items-center gap-x-1">
                <Input
                  value={value}
                  onChange={handleInput}
                  className="px-2 h-8 w-16 text-right"
                />
                % commission
              </div>
            </FormLabel>
          </div>
          <FormControl>
            <Slider
              max={200}
              value={[value.toString()]}
              step={1}
              onValueChange={(vals) => {
                onChange(vals[0].toString());
              }}
              className="[&_[role=slider]]:h-4 [&_[role=slider]]:w-4"
              aria-label="Rate"
              // value={[form.getValues("rate")]}
            />
          </FormControl>
        </div>
        {/* </HoverCardTrigger>
        <HoverCardContent
          align="start"
          className="w-[260px] text-sm"
          side="left"
        >
          Controls randomness: lowering results in less random completions. As
          the temperature approaches zero, the model will become deterministic
          and repetitive.
        </HoverCardContent>
      </HoverCard> */}
      </div>
      <FormMessage />
    </FormItem>
  );
}
