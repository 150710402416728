import { SignedInUser } from "@revelate/types";
import { supabaseClient } from "./client";

export const getUser = async (userId: string, from?: Date, to?: Date) => {
  let query = supabaseClient
    .from("users")
    .select("*, company:companies!public_profiles_company_id_fkey(*)")
    .eq("id", userId);
  // Filter by dates if available
  if (from && to) {
    const fromMonth = from.getMonth();
    const fromYear = from.getFullYear();
    const toMonth = to.getMonth();
    const toYear = to.getFullYear();
    query = query
      .gte("commissions.month", fromMonth)
      .gte("commissions.year", fromYear)
      .lte("commissions.month", toMonth)
      .lte("commissions.year", toYear);
  }

  const { data, error } = await query
    .returns<SignedInUser[]>()
    .limit(1)
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

export const changeCompany = async (
  user: SignedInUser,
  newCompanyId: number
) => {
  if (!user) return null;
  const { data, error } = await supabaseClient
    .from("users")
    .update({ company_id: newCompanyId })
    .eq("id", user.id)
    .select("*, company:companies!public_profiles_company_id_fkey(*)")
    .returns<SignedInUser>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

// export const getUsers = async (companyId: number) => {
//   const { data, error } = await supabaseClient
//     .from("users")
//     .select(
//       "*, quotas(*), teams(*), plans(*), deals(*), currency:currencies!users_currency_id_fkey(*)"
//     )
//     .eq("company_id", companyId)
//     .returns<NestedUser[]>();
//   if (error) {
//     throw error;
//   }
//   return data;
// };
