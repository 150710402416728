import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { getScope, getTimePeriodFromDateRange } from "@/lib/app";
import { getRoute, getScopeRoute } from "@/lib/routes";
import { useAppStore } from "@/stores";
import { DomainType, ListItem, ScopeType } from "@revelate/types";

import { Link } from "wouter";
import { Route, Switch } from "wouter";

const BreadcrumbFragment = ({
  label,
  href,
  withSeparator,
}: {
  label: string;
  href: string;
  withSeparator?: boolean;
}) => {
  return (
    <>
      {withSeparator && <BreadcrumbSeparator />}

      <BreadcrumbItem>
        <BreadcrumbLink asChild>
          <Link href={href}>{label}</Link>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </>
  );
};

export const Breadcrumbs = () => {
  // const list = useAppStore((state) => state.list);
  const dateRange = useAppStore((state) => state.dateRange);
  const scope = useAppStore((state) => state.scope);

  return (
    <Breadcrumb className="hidden md:flex px-2">
      <BreadcrumbList>
        {/* <BreadcrumbItem>
          <Switch>
            <Route path="/reports/*?">
              <BreadcrumbLink>Reports</BreadcrumbLink>
            </Route>
            <Route>
              <BreadcrumbLink>Dashboard</BreadcrumbLink>
            </Route>
          </Switch>
        </BreadcrumbItem>
        <BreadcrumbSeparator /> */}

        <Switch>
          <Route path="/reports/*?">
            <BreadcrumbFragment label="Reports" href="/reports" />
          </Route>
          <Route path="/plans/*?">
            <BreadcrumbFragment label="Compensation Plans" href="/plans" />
          </Route>
          <Route path="/quotas/*?">
            <BreadcrumbFragment label="Quotas" href="/quotas" />
          </Route>
          <Route path="/currencies/*?">
            <BreadcrumbFragment label="Currencies" href="/currencies" />
          </Route>
          <Route path="/users/*?">
            <BreadcrumbFragment label="Users" href="/users" />
          </Route>
          <Route path="/deals/*?">
            <BreadcrumbFragment label="Deals" href="/deals" />
          </Route>
          <Route path="/integrations/*?">
            <BreadcrumbFragment label="Integrations" href="/integrations" />
          </Route>
          <Route>
            <BreadcrumbFragment label="Dashboard" href="/dashboard" />
          </Route>
        </Switch>

        {/* <BreadcrumbItem>
          <Switch>
            <Route path="*users/:id">
              <BreadcrumbLink>Employees</BreadcrumbLink>
            </Route>
            <Route path="*teams/:id">
              <BreadcrumbLink>Teams</BreadcrumbLink>
            </Route>
            <Route>Company</Route>
          </Switch>
        </BreadcrumbItem>
        <BreadcrumbSeparator /> */}

        <Switch>
          <Route path="/reports/:type?/:id?/*?">
            <BreadcrumbFragment
              withSeparator
              label={getTimePeriodFromDateRange(dateRange)}
              href="/reports"
            />
          </Route>
        </Switch>

        <Route path="/:domain/:type/:id/*?">
          {(params) => {
            // const scope = getScope(
            //   list,
            //   params.type as ScopeType | undefined,
            //   params.id
            // );
            return (
              <BreadcrumbFragment
                withSeparator
                label={scope?.label || "Add new item"}
                href={
                  scope && params.domain
                    ? getScopeRoute(params.domain as DomainType, scope)
                    : "/"
                }
              />
            );
          }}
        </Route>

        <Switch>
          <Route path="/:domain/:type/:id/users">
            {(params) => (
              <BreadcrumbFragment
                withSeparator
                label="Users"
                href={getRoute(
                  params.domain as DomainType,
                  params.type as ScopeType,
                  params.id,
                  "users"
                )}
              />
            )}
          </Route>
          <Route path="/quotas/quota/:id/goals/*?">
            {(params) => (
              <BreadcrumbFragment
                withSeparator
                label="Sales Goals"
                href={getRoute("quotas", "quota", params.id, "goals")}
              />
            )}
          </Route>
          <Route path="/plans/plan/:id/accelerators/*?">
            {(params) => (
              <BreadcrumbFragment
                withSeparator
                label="Accelerators"
                href={getRoute("plans", "plan", params.id, "accelerators")}
              />
            )}
          </Route>
          {/* <Route path="/currencies/currency/:id/exchange_rates/*?">
            {(params) => (
              <BreadcrumbFragment
                withSeparator
                label="Exchange Rates"
                href={getRoute(
                  "currencies",
                  "currency",
                  params.id,
                  "exchange_rates"
                )}
              />
            )}
          </Route> */}
          <Route path="/:domain/:type/:id">
            {(params) =>
              params.domain !== "dashboard" && params.domain !== "reports" ? (
                <BreadcrumbFragment
                  withSeparator
                  label="General"
                  href={getRoute(
                    params.domain as DomainType,
                    params.type as ScopeType,
                    params.id
                  )}
                />
              ) : (
                <></>
              )
            }
          </Route>
        </Switch>

        <Switch>
          <Route path="/plans/plan/:id/accelerators/edit/:acceleratorId">
            {(params) => (
              <BreadcrumbFragment
                withSeparator
                label="Edit"
                href={`/plans/${params.id}/accelerators/edit/${params.acceleratorId}`}
              />
            )}
          </Route>
        </Switch>
      </BreadcrumbList>
    </Breadcrumb>
  );
};
