import { TeamWithUsers } from "@revelate/types";

export const getUserIdsForTeam = (
  teams: TeamWithUsers[],
  id: number,
  primaryOnly = false
) => {
  const team = teams.find((team) => team.id === id);
  if (!team) return [];
  return team.users
    .filter((user) => !primaryOnly || (primaryOnly && user.is_primary))
    .map((user) => user.user_id.toString());
};
