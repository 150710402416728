// import Image from "next/image";
// import Link from "next/link";

import { Card, CardContent } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import {
  criteriaRadioButtons,
  getCommissionBasisRadioButtons,
} from "@/lib/app";

import { getPercentage } from "@revelate/calc";
import { Pencil } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useLocation } from "wouter";
import { NestedPlan } from "@revelate/types";
import { ConfirmDeletionDialog } from "../confirm-deletion";
import { deleteAccelerator } from "@/lib/supabase/accelerators";
import type { KeyedMutator } from "swr";
import { getCurrencyFormatted } from "@/lib/app";

export function AcceleratorsTable({
  plan,
  mutate,
}: {
  plan: NestedPlan;
  mutate: KeyedMutator<NestedPlan | null>;
}) {
  const { accelerators } = plan || {};
  const [, setLocation] = useLocation();

  return (
    <Card x-chunk="dashboard-05-chunk-3">
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Rate</TableHead>
              <TableHead className="hidden sm:table-cell">Name</TableHead>
              <TableHead className="hidden sm:table-cell">
                Commission basis
              </TableHead>
              <TableHead className="hidden md:table-cell">Criteria</TableHead>
              <TableHead className="hidden md:table-cell">Conditions</TableHead>

              {/* <TableHead className="text-right"></TableHead> */}
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {accelerators
              ?.sort((a, b) => a.rate - b.rate)
              .map((accelerator, index) => {
                const basis =
                  accelerator?.commission_based_on === "fixed_value"
                    ? getCurrencyFormatted(accelerator?.fixed_value_amount || 0)
                    : getCommissionBasisRadioButtons(plan)
                        .find(
                          (c) => c?.value === accelerator?.commission_based_on
                        )
                        ?.label.split("of")[0];

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="font-medium">
                        {getPercentage(accelerator.rate)}
                      </div>
                    </TableCell>
                    <TableCell className="hidden sm:table-cell">
                      {accelerator.name}
                    </TableCell>

                    <TableCell className="hidden sm:table-cell">
                      {basis}
                    </TableCell>
                    <TableCell className="hidden md:table-cell">
                      {
                        criteriaRadioButtons.find(
                          (c) => c?.value === accelerator?.criteria
                        )?.label
                      }
                    </TableCell>
                    <TableCell className="hidden md:table-cell">
                      {accelerator?.conditions.length > 0
                        ? accelerator?.conditions.length
                        : "None"}{" "}
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center gap-3">
                        <Button
                          onClick={() => setLocation(`/edit/${accelerator.id}`)}
                          variant="default"
                          size="icon"
                          className="bg-[#6B6397]"
                        >
                          <Pencil className="h-4 w-4" />
                        </Button>

                        <ConfirmDeletionDialog
                          key={index}
                          deleteId={accelerator.id.toString()}
                          deleteOperation={async (id) => {
                            await deleteAccelerator(Number(id));
                            const updatedAccelerators = accelerators.filter(
                              (accelerator) => accelerator.id !== Number(id)
                            );
                            mutate({
                              ...plan,
                              accelerators: updatedAccelerators,
                            });
                          }}
                        />
                      </div>
                    </TableCell>
                    {/* <TableCell className="text-right">
                    {accelerator?.criteria}
                  </TableCell> */}
                    {/* <TableCell>
                    <div className="ml-2 font-medium">
                      <Button
                        onClick={() => setLocation(getRoute("plans", "plan", plan.id))}
                        variant="outline"
                        size="icon"
                      >
                        <Pencil className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
