import { Separator } from "@/components/ui/separator";
import { AcceleratorsForm } from "./accelerators-form";
import { AcceleratorsTable } from "./accelerators-table";
import { Button } from "@/components/ui/button";
import { Route, Switch, useLocation, useParams } from "wouter";
import { NestedPlan } from "@revelate/types";
import type { KeyedMutator } from "swr";
import { Plus } from "lucide-react";

const AcceleratorsFormPage = ({
  plan,
  mutate,
}: {
  plan: NestedPlan;
  mutate: KeyedMutator<NestedPlan | null>;
}) => {
  const { acceleratorId } = useParams();
  return (
    <div className="space-y-6">
      <div className="flex">
        <div>
          <h3 className="text-lg font-medium">
            {acceleratorId ? "Edit" : "Add new"} accelerator for {plan.name}
          </h3>
          <p className="text-sm text-muted-foreground">
            Configure the rate and criteria for this accelerator.
          </p>
        </div>
      </div>
      <AcceleratorsForm plan={plan} mutate={mutate} />
    </div>
  );
};

export default function AcceleratorsPage({
  plan,
  mutate,
}: {
  plan: NestedPlan;
  mutate: KeyedMutator<NestedPlan | null>;
}) {
  const [, setLocation] = useLocation();

  return (
    <Switch>
      <Route path="/">
        <div className="space-y-6">
          <div className="flex">
            <div>
              <h3 className="text-lg font-medium">Accelerators</h3>
              <p className="text-sm text-muted-foreground">
                Add accelerators related to this plan and set the critera for
                them to apply.
              </p>
            </div>
            <div className="ml-auto flex items-center gap-2">
              <Button
                onClick={() => setLocation("/edit")}
                className="bg-[#6B6397]"
              >
                <Plus className="mr-2 h-4 w-4" /> Add new accelerator
              </Button>
            </div>
          </div>
          <Separator />
          <AcceleratorsTable plan={plan} mutate={mutate} />
        </div>
      </Route>
      <Route path="/edit/:acceleratorId?">
        <AcceleratorsFormPage plan={plan} mutate={mutate} />
      </Route>
    </Switch>
  );
}
