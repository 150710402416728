"use client";

import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { DataTableViewOptions } from "./data-table-view-options";

import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import { dealTypesRadioButtons } from "@/lib/app";
import { useAppStore } from "@/stores";
import useSWR from "swr";
import { TableType, getAll } from "@/lib/supabase";
import { Loader } from "@/components/Loader";
import { NestedUser, TokenProvider } from "@revelate/types";
import { getFullName } from "@revelate/utils";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function DataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;
  const currentUser = useAppStore((state) => state.currentUser);
  const {
    data: users,
    error: usersError,
    isLoading: usersIsLoading,
  } = useSWR(
    [
      "users" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]): Promise<NestedUser[]> => getAll(table, companyId)
  );

  const {
    data: currencies,
    error,
    isLoading,
  } = useSWR(
    [
      "currencies" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId)
  );

  const currencyFilters = currencies?.map((currency) => ({
    label: currency.code,
    value: currency.code,
  }));

  const ownerFilters = users?.map((user) => ({
    label: getFullName(user),
    value: user.id,
  }));

  type Provider = {
    label: string;
    value: TokenProvider;
  };

  const providers: Provider[] = [
    {
      label: "Hubspot",
      value: "hubspot",
    },
    {
      label: "Fortnox",
      value: "fortnox",
    },
    {
      label: "Pipedrive",
      value: "pipedrive",
    },
    {
      label: "Salesforce",
      value: "salesforce",
    },
    {
      label: "Zoho",
      value: "zoho",
    },
    {
      label: "Freshdesk",
      value: "freshdesk",
    },
  ];

  const statuses = [
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Closed",
      value: "Closed",
    },
    {
      label: "Won",
      value: "Won",
    },
    {
      label: "Invoiced",
      value: "Invoiced",
    },
    {
      label: "Read Only",
      value: "Read Only",
    },
  ];

  if (
    usersIsLoading ||
    isLoading ||
    !currencies ||
    !currencyFilters ||
    !ownerFilters
  )
    return <Loader />;

  if (usersError || error) return <div>Error fetching data..</div>;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Filter deals..."
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("name")?.setFilterValue(event.target.value)
          }
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("owner") && (
          <DataTableFacetedFilter
            column={table.getColumn("owner")}
            title="Owners"
            options={ownerFilters}
          />
        )}
        {table.getColumn("deal_type") && (
          <DataTableFacetedFilter
            column={table.getColumn("deal_type")}
            title="Deal types"
            options={dealTypesRadioButtons}
          />
        )}
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statuses}
          />
        )}
        {table.getColumn("currency_code") && (
          <DataTableFacetedFilter
            column={table.getColumn("currency_code")}
            title="Currencies"
            options={currencyFilters}
          />
        )}
        {table.getColumn("provider") && (
          <DataTableFacetedFilter
            column={table.getColumn("provider")}
            title="Providers"
            options={providers}
          />
        )}

        {/* {table.getColumn("priority") && (
          <DataTableFacetedFilter
            column={table.getColumn("priority")}
            title="Priority"
            options={priorities}
          />
        )}  */}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
