import { cn } from "@/lib/utils";

export const LogoIcon = ({ className }: { className: string }) => (
  <svg
    className={cn("w-101 h-127", className)}
    // width="101"
    // height="127"
    viewBox="0 0 101 127"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="99" height="99" rx="10" fill="#5E548E" />
    <path
      d="M22 12H53.0705H56.6458C60.9152 12 64.5746 12.8051 67.6242 14.4153C70.6738 16.0255 72.9779 18.2063 74.5365 20.9579C76.0952 23.7085 76.8745 26.7276 76.8745 30.0152C76.8745 33.8399 75.8749 37.1144 73.8758 39.8387C71.8766 42.563 69.3353 44.6146 66.2519 45.9935C63.1685 47.3725 59.9664 48.062 56.6458 48.062H53.1118H22V12ZM46.3964 50.2817L77 86H46.3964V50.2817Z"
      fill="#BE95C4"
    />
  </svg>
);

export const Logo = ({ className }: { className: string }) => (
  <svg
    className={cn("w-451 h-83", className)}
    // width="451"
    // height="83"
    viewBox="0 0 451 83"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 0H36.8951H40.7954C45.4529 0 49.4451 0.88125 52.7719 2.64374C56.0986 4.40624 58.6122 6.79343 60.3126 9.80532C62.0129 12.8161 62.8631 16.1207 62.8631 19.7194C62.8631 23.9059 61.7727 27.4901 59.5918 30.4721C57.4109 33.454 54.6386 35.6997 51.2748 37.2091C47.9111 38.7185 44.4179 39.4732 40.7954 39.4732H36.9402H3V0ZM29.6143 41.903L63 81H29.6143V41.903Z"
      fill="#BE95C4"
    />
    <path
      d="M84.2 54.9C84.2 38.5 95.2 26.2 114.1 27.9V43.4C106.1 42.9 100.4 48.7 100.4 56.6V81H84.2V54.9ZM113.109 54.5C113.109 38.1 124.709 26.7 141.209 26.7C158.609 26.7 171.909 39 169.509 60.1H129.509C130.409 65.8 135.209 69.8 141.409 69.9C144.909 70 148.209 68.6 150.009 67.2H167.309C163.009 76.2 153.809 82.2 141.209 82.2C124.609 82.2 113.109 70.8 113.109 54.5ZM129.509 49.3H153.209C152.909 47.7 152.309 46 151.109 44.5C148.809 41.4 145.309 39.8 141.309 39.8C136.809 39.8 133.609 41.7 131.409 44.6C130.309 46 129.809 47.5 129.509 49.3ZM184.19 81L166.39 27.9H182.59L192.29 62.7L201.99 27.9H218.19L200.49 81H184.19ZM214.933 54.5C214.933 38.1 226.533 26.7 243.033 26.7C260.433 26.7 273.733 39 271.333 60.1H231.333C232.233 65.8 237.033 69.8 243.233 69.9C246.733 70 250.033 68.6 251.833 67.2H269.133C264.833 76.2 255.633 82.2 243.033 82.2C226.433 82.2 214.933 70.8 214.933 54.5ZM231.333 49.3H255.033C254.733 47.7 254.133 46 252.933 44.5C250.633 41.4 247.133 39.8 243.133 39.8C238.633 39.8 235.433 41.7 233.233 44.6C232.133 46 231.633 47.5 231.333 49.3ZM275.491 81V7.79999H291.591V81H275.491ZM353.668 54.1V81H342.768L342.168 73.2C337.868 78.6 331.068 82.1 323.568 82.1C307.968 82.1 295.868 70.5 296.068 54.3C296.268 37.9 308.068 26.7 324.868 26.7C341.568 26.7 353.468 37 353.668 54.1ZM311.168 54.4C311.168 62.3 316.968 68 324.868 68C332.768 68 338.568 62.3 338.568 54.4C338.568 46.3 332.768 40.8 324.868 40.8C316.968 40.8 311.168 46.3 311.168 54.4ZM364.604 81V39.5H357.404V27.9H364.604V7.79999H381.304V27.9H388.104V39.5H381.304V81H364.604ZM388.308 54.5C388.308 38.1 399.908 26.7 416.408 26.7C433.808 26.7 447.108 39 444.708 60.1H404.708C405.608 65.8 410.408 69.8 416.608 69.9C420.108 70 423.408 68.6 425.208 67.2H442.508C438.208 76.2 429.008 82.2 416.408 82.2C399.808 82.2 388.308 70.8 388.308 54.5ZM404.708 49.3H428.408C428.108 47.7 427.508 46 426.308 44.5C424.008 41.4 420.508 39.8 416.508 39.8C412.008 39.8 408.808 41.7 406.608 44.6C405.508 46 405.008 47.5 404.708 49.3Z"
      fill="#231942"
    />
  </svg>
);

export const LogoInverted = ({ className }: { className: string }) => (
  <svg
    className={cn("w-451 h-83", className)}
    // width="451"
    // height="83"
    viewBox="0 0 451 83"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 0H36.8951H40.7954C45.4529 0 49.4451 0.88125 52.7719 2.64374C56.0986 4.40624 58.6122 6.79343 60.3126 9.80532C62.0129 12.8161 62.8631 16.1207 62.8631 19.7194C62.8631 23.9059 61.7727 27.4901 59.5918 30.4721C57.4109 33.454 54.6386 35.6997 51.2748 37.2091C47.9111 38.7185 44.4179 39.4732 40.7954 39.4732H36.9402H3V0ZM29.6143 41.903L63 81H29.6143V41.903Z"
      fill="#BE95C4"
    />
    <path
      d="M84.2 54.9C84.2 38.5 95.2 26.2 114.1 27.9V43.4C106.1 42.9 100.4 48.7 100.4 56.6V81H84.2V54.9ZM113.109 54.5C113.109 38.1 124.709 26.7 141.209 26.7C158.609 26.7 171.909 39 169.509 60.1H129.509C130.409 65.8 135.209 69.8 141.409 69.9C144.909 70 148.209 68.6 150.009 67.2H167.309C163.009 76.2 153.809 82.2 141.209 82.2C124.609 82.2 113.109 70.8 113.109 54.5ZM129.509 49.3H153.209C152.909 47.7 152.309 46 151.109 44.5C148.809 41.4 145.309 39.8 141.309 39.8C136.809 39.8 133.609 41.7 131.409 44.6C130.309 46 129.809 47.5 129.509 49.3ZM184.19 81L166.39 27.9H182.59L192.29 62.7L201.99 27.9H218.19L200.49 81H184.19ZM214.933 54.5C214.933 38.1 226.533 26.7 243.033 26.7C260.433 26.7 273.733 39 271.333 60.1H231.333C232.233 65.8 237.033 69.8 243.233 69.9C246.733 70 250.033 68.6 251.833 67.2H269.133C264.833 76.2 255.633 82.2 243.033 82.2C226.433 82.2 214.933 70.8 214.933 54.5ZM231.333 49.3H255.033C254.733 47.7 254.133 46 252.933 44.5C250.633 41.4 247.133 39.8 243.133 39.8C238.633 39.8 235.433 41.7 233.233 44.6C232.133 46 231.633 47.5 231.333 49.3ZM275.491 81V7.79999H291.591V81H275.491ZM353.668 54.1V81H342.768L342.168 73.2C337.868 78.6 331.068 82.1 323.568 82.1C307.968 82.1 295.868 70.5 296.068 54.3C296.268 37.9 308.068 26.7 324.868 26.7C341.568 26.7 353.468 37 353.668 54.1ZM311.168 54.4C311.168 62.3 316.968 68 324.868 68C332.768 68 338.568 62.3 338.568 54.4C338.568 46.3 332.768 40.8 324.868 40.8C316.968 40.8 311.168 46.3 311.168 54.4ZM364.604 81V39.5H357.404V27.9H364.604V7.79999H381.304V27.9H388.104V39.5H381.304V81H364.604ZM388.308 54.5C388.308 38.1 399.908 26.7 416.408 26.7C433.808 26.7 447.108 39 444.708 60.1H404.708C405.608 65.8 410.408 69.8 416.608 69.9C420.108 70 423.408 68.6 425.208 67.2H442.508C438.208 76.2 429.008 82.2 416.408 82.2C399.808 82.2 388.308 70.8 388.308 54.5ZM404.708 49.3H428.408C428.108 47.7 427.508 46 426.308 44.5C424.008 41.4 420.508 39.8 416.508 39.8C412.008 39.8 408.808 41.7 406.608 44.6C405.508 46 405.008 47.5 404.708 49.3Z"
      fill="white"
    />
  </svg>
);
