import { getCurrencyFormatted } from "@/lib/app";
import { useAppStore } from "@/stores";
import { getDealsForDateRange } from "@revelate/calc";
import { NestedDeal } from "@revelate/types";
import { format } from "date-fns";

export function RecentDeals({ deals }: { deals: NestedDeal[] }) {
  const { scope, dateRange } = useAppStore((state) => state);

  const scopedDeals = getDealsForDateRange({
    deals: deals || [],
    dateRange,
    scope,
  });

  return (
    <div className="space-y-8">
      {scopedDeals.map((deal, index) => {
        return (
          <div className="flex items-center" key={index}>
            {/* <Avatar className="h-9 w-9">
            <AvatarImage src={user.avatar} alt="Avatar" />
            <AvatarFallback>{user.avatar_fallback}</AvatarFallback>
          </Avatar> */}
            <div className="ml-0 space-y-1">
              <p className="text-sm font-medium leading-none">
                {deal.name} {deal.id}
              </p>
              <p className="text-sm text-muted-foreground">
                {getCurrencyFormatted(deal.value, deal.currency_code)} deal
                value
                {deal.onboarding_value
                  ? ` (${getCurrencyFormatted(
                      deal.onboarding_value,
                      deal.currency_code
                    )} onboarding fee)`
                  : ""}
                {deal.closed_at
                  ? ` closed ${format(deal.closed_at, "MMM dd, yyyy")}`
                  : "not closed"}
              </p>
            </div>
            <div className="ml-auto text-right font-medium">
              {/* {getCurrencyFormatted(deal.value, deal.currency_code)} */}
            </div>
          </div>
        );
      })}
    </div>
  );
}
