import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@/components/ui/table";
import { AcceleratorWithConditions, NestedPlan, TODO } from "@revelate/types";
import { getCommissionDealTypeLabels, getProviderLabel } from "@/lib/app";
import { Badge } from "@/components/ui/badge";
import {
  capitalizeFirstLetter,
  getFullName,
  getUserInitials,
} from "@revelate/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { getPercentageNumeric, stringifyPercentage } from "@revelate/calc";
import { Button } from "@/components/ui/button";
import { ConfirmDeletionDialog } from "../edit/confirm-deletion";
import { Pencil } from "lucide-react";
import { useState } from "react";
import { DataTableColumnHeader } from "@/deals/components/data-table-column-header";
import { integrations } from "@/constants/integrations";

const getPlanAcceleratorsRange = (
  accelerators: AcceleratorWithConditions[]
): [number, number?] | null => {
  if (!accelerators || accelerators.length === 0) return null;

  let lowestRate = accelerators[0].rate;
  let highestRate = accelerators[0].rate;

  accelerators.forEach((accelerator) => {
    if (accelerator.rate < lowestRate) {
      lowestRate = accelerator.rate;
    }
    if (accelerator.rate > highestRate) {
      highestRate = accelerator.rate;
    }
  });

  if (lowestRate === highestRate) {
    return [getPercentageNumeric(lowestRate)];
  }

  return [getPercentageNumeric(lowestRate), getPercentageNumeric(highestRate)];

  // return `${getPercentage(lowestRate)} - ${getPercentage(highestRate)}`;
};

function stringifyPlanAcceleratorsRange(range: [number, number?] | null) {
  if (range === null) return "";

  if (range.length === 1) return stringifyPercentage(range[0]);
  if (range.length === 2 && typeof range[1] === "number")
    return `${stringifyPercentage(range[0])} - ${stringifyPercentage(
      range[1]
    )}`;

  return "";
}

interface CreatePlansTableColumnsProps {
  goToPlan: (planId: string) => void;
  onDeletePlan: (planId: string) => Promise<void>;
}

export function createPlansTableColumns({
  goToPlan,
  onDeletePlan,
}: CreatePlansTableColumnsProps): ColumnDef<NestedPlan, TODO>[] {
  return [
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return (
          <>
            <div className="font-medium">{row?.original?.name}</div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              {capitalizeFirstLetter(row?.original?.time_period || "Month")}
              ly plan for{" "}
              {getCommissionDealTypeLabels(row?.original?.deal_types)}
            </div>
          </>
        );
      },
      enableSorting: true,
      sortingFn: (rowA, rowB) =>
        rowA?.original?.name?.localeCompare(rowB?.original?.name),
      enableHiding: false,
    },
    {
      id: "commission",
      accessorKey: "accelerators",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Commission" />
      ),
      cell: ({ row }) => {
        return (
          <>
            <div className="font-medium">
              {" "}
              {row?.original?.accelerators?.length > 0 ? (
                `${stringifyPlanAcceleratorsRange(
                  getPlanAcceleratorsRange(row?.original?.accelerators)
                )}`
              ) : (
                <Badge
                  className="text-xs"
                  variant={
                    row?.original?.accelerators?.length > 0
                      ? "paid"
                      : "destructive"
                  }
                >
                  Needs action
                </Badge>
              )}
            </div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              {row?.original?.accelerators.length > 0
                ? row?.original?.accelerators.length
                : "No"}{" "}
              accelerators
            </div>
          </>
        );
      },
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const rowARange = getPlanAcceleratorsRange(
          rowA?.original?.accelerators
        );
        const rowBRange = getPlanAcceleratorsRange(
          rowB?.original?.accelerators
        );

        if (rowARange === null) return 1;
        if (rowBRange === null) return -1;

        return rowARange[0] - rowBRange[0];
      },
      enableHiding: false,
    },
    {
      id: "assigned-to",
      header: "Assigned to",
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-1">
            {row?.original?.users?.map((u) => (
              <Tooltip key={u?.id}>
                <TooltipTrigger>
                  <Avatar className="h-8 w-8">
                    <AvatarImage src={u?.avatar_url || ""} />
                    <AvatarFallback>{getUserInitials(u)}</AvatarFallback>
                  </Avatar>
                </TooltipTrigger>
                <TooltipContent>{getFullName(u)}</TooltipContent>
              </Tooltip>
            ))}
          </div>
        );
      },
    },
    {
      id: "providers",
      header: "Deal Providers",
      cell: ({ row }) => {
        return row?.original?.providers ? (
          <div className="gap-1 flex">
            {row?.original?.providers?.map((provider) => (
              <Badge className="text-xs" variant="secondary">
                {getProviderLabel(provider)}
              </Badge>
            ))}
          </div>
        ) : (
          <Badge className="text-xs" variant="secondary">
            Any
          </Badge>
        );
      },
    },
    {
      id: "status",
      accessorKey: "status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        return (
          <Badge
            className="text-xs"
            variant={
              row?.original?.status === "published"
                ? "paid"
                : row?.original?.status === "draft"
                  ? "approved"
                  : "secondary"
            }
          >
            {capitalizeFirstLetter(row?.original?.status)}
          </Badge>
        );
      },
      enableSorting: true,
      sortingFn: (rowA, rowB) =>
        rowA?.original?.status?.localeCompare(rowB?.original?.status),
      enableHiding: false,
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-3">
            <Button
              onClick={() => goToPlan(row?.original?.id?.toString())}
              variant="default"
              size="icon"
              className="bg-[#6B6397]"
            >
              <Pencil className="h-4 w-4" />
            </Button>

            <ConfirmDeletionDialog
              key={row?.index}
              deleteId={row?.original?.id.toString()}
              deleteOperation={() =>
                onDeletePlan(row?.original?.id?.toString())
              }
            />
          </div>
        );
      },
    },
  ];
}

interface PlansTableProps<TData, TValue> {
  plans: NestedPlan[];
  columns: ColumnDef<TData, TValue>[];
}

export function PlansTable({ plans, columns }: PlansTableProps<TODO, TODO>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: plans,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
