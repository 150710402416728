import { getScope } from "@/lib/app";
import { useAppStore } from "@/stores";
import { ListItem, ScopeType } from "@revelate/types";
import { useEffect } from "react";
import { useRoute } from "wouter";

export const useScope = ({ list }: { list: ListItem[] }) => {
  const { currentUser, setScope } = useAppStore((state) => state);

  const [, params] = useRoute("/:domain?/:type?/:id?/*?");
  const { type, id }: { type?: string; id?: string } = params || {};

  useEffect(() => {
    const scope = getScope(
      list,
      type as ScopeType | undefined,
      id || currentUser?.id,
      currentUser?.role
    );
    if (scope) setScope(scope);
  }, [type, id, list, currentUser]);
};
