import * as React from "react";
import { CheckIcon, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { getFullName, sortByStringKey } from "@revelate/utils";
import { NestedQuota } from "@revelate/types";
import { useAppStore } from "@/stores";
import type { KeyedMutator } from "swr";
import { useParams } from "wouter";
import { addUserToGoal } from "@/lib/supabase/goals";
import { cn } from "@/lib/utils";
import useSWR from "swr";
import { TableType, getAll } from "@/lib/supabase";

export function EmployeeCombobox({
  quota,
  mutate,
}: {
  quota: NestedQuota;
  mutate: KeyedMutator<NestedQuota | null>;
}) {
  const [open, setOpen] = React.useState(false);
  const currentUser = useAppStore((state) => state.currentUser);
  const { data: employees } = useSWR(
    [
      "users" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId)
  );

  const { goals } = quota || {};
  const { goalId } = useParams();
  const goal = goals?.find((g) => g.id === Number(goalId));
  const { users } = goal || {};
  const userIds = users?.map((u) => u.id);

  const handleSelect = async (fullName: string) => {
    if (!goal) return;
    setOpen(false);
    const user = employees?.find((e) => getFullName(e) === fullName);
    if (!user) return;
    await addUserToGoal(goal.id, user.id);
    const updatedUsers = [...(goal?.users || []), user];
    mutate({
      ...quota,
      goals: goals?.map((g) =>
        g.id === goal.id ? { ...g, users: updatedUsers } : g
      ),
    } as NestedQuota);
  };

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button disabled={!goalId} className="bg-[#6B6397]">
            <Plus className="mr-2 h-4 w-4" /> Add new employee
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" side="right" align="start">
          <Command>
            <CommandInput placeholder="Search employee..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {employees?.sort(sortByStringKey("first_name")).map((user) => (
                  <CommandItem
                    key={user.id}
                    value={getFullName(user)}
                    onSelect={(id) => handleSelect(id)}
                  >
                    <span>{getFullName(user)}</span>
                    <CheckIcon className={cn("ml-auto h-4 w-4", "opacity-0")} />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
