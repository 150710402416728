import { DomainType, Scope, ScopeType } from "@revelate/types";

export const getScopeRoute = (
  domain: DomainType,
  scope: Scope | null,
  allowedScopes?: ScopeType[]
) => {
  if (!scope || (allowedScopes && !allowedScopes.includes(scope.type)))
    return `/${domain}`;
  return `/${domain}/${scope.type}/${scope.value}`;
};

export const getRoute = (
  domain: DomainType,
  type: ScopeType,
  id: string,
  subRoute?: string
) => {
  return `/${domain}/${type}/${id}${subRoute ? `/${subRoute}` : ""}`;
};
