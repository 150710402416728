import { Database } from "@revelate/types/supabase";

export const getFullName = (
  user: Database["public"]["Tables"]["users"]["Row"] | null
) => {
  const { first_name, last_name } = user || {};
  if (!user || !first_name || !last_name) return "(Unnamed user)";
  return `${user.first_name} ${user.last_name}`;
};

export const getUserInitials = (
  user: Database["public"]["Tables"]["users"]["Row"] | null
) => {
  const { first_name, last_name } = user || {};
  if (!user || !first_name || !last_name)
    return user?.email.substring(0, 2).toUpperCase() || "";
  return (
    `${user.first_name?.charAt(0)}${user.last_name?.charAt(0)}`.toUpperCase() ||
    ""
  );
};
