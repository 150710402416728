import { useAppStore } from "@/stores";

import { cn } from "@/lib/utils";
import { IntegrationCard } from "./components/card";
import { integrations } from "@/constants/integrations";
import useSWR from "swr";
import { TableType, get, getAll } from "@/lib/supabase";
import { Loader } from "@/components/Loader";
import { LoadingError } from "@/components/LoadingError";

function DemoContainer({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "flex items-center justify-center [&>div]:w-full h-full",
        className
      )}
      {...props}
    />
  );
}

export function IntegrationsPage() {
  const currentUser = useAppStore((state) => state.currentUser);
  const {
    data: company,
    error: companyError,
    isLoading: companyIsLoading,
  } = useSWR(
    [
      "companies" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => get(table, companyId)
  );
  const {
    data: tokens,
    error,
    isLoading,
    mutate,
  } = useSWR(
    [
      "tokens" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId)
  );
  if (companyIsLoading || isLoading || !company || !tokens) return <Loader />;
  if (companyError || error) return <LoadingError />;
  return (
    <>
      <div className="flex items-center justify-between space-y-2">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Integrations</h2>
          <p className="text-muted-foreground">
            Connect Revelate to your favorite tools and services.
          </p>
        </div>
      </div>
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-3">
        <div className="hidden items-start justify-center gap-6 rounded-lg p-0 md:grid lg:grid-cols-2 xl:grid-cols-3">
          {integrations.map((integration) => (
            <DemoContainer key={integration.id}>
              <IntegrationCard
                integration={integration}
                tokens={tokens}
                company={company}
                mutate={mutate}
              />
            </DemoContainer>
          ))}
        </div>
      </div>
    </>
  );
}
