import { getColumns } from "./components/columns";
import { DataTable } from "./components/data-table";

import { TableType, getAll } from "@/lib/supabase";
import useSWR from "swr";
import { useAppStore } from "@/stores";
import { Loader } from "@/components/Loader";
import { LoadingError } from "@/components/LoadingError";
import { useAPI } from "@/hooks/useAPI";
import { useState } from "react";

export const DealsPage = () => {
  const currentUser = useAppStore((state) => state.currentUser);
  const {
    data: deals,
    error,
    isLoading,
    mutate,
  } = useSWR(
    [
      "deals" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId)
  );
  const { syncDealsByProvider, isLoading: isAPILoading } = useAPI();
  const [clickedRow, setClickedRow] = useState<string | null>(null);

  if (isLoading || !deals || !currentUser) {
    return <Loader />;
  }

  if (error) {
    return <LoadingError />;
  }

  return (
    <>
      <div className="flex items-center justify-between space-y-2">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Deals</h2>
          <p className="text-muted-foreground">
            Verify data and manually sync deals with your CRM here.
          </p>
        </div>
      </div>
      <DataTable
        data={deals}
        columns={getColumns({
          syncDealsByProvider,
          isAPILoading,
          clickedRow,
          setClickedRow,
          mutate,
        })}
      />
    </>
  );
};
