import { Separator } from "@/components/ui/separator";
import { BasicsForm } from "./settings-form";
import { NestedPlan, NestedToken } from "@revelate/types";
import type { KeyedMutator } from "swr";

export const SettingsPage = ({
  plan,
  tokens,
  mutate,
}: {
  plan?: NestedPlan;
  tokens?: NestedToken[];
  mutate: KeyedMutator<NestedPlan | null>;
}) => {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">General</h3>
        <p className="text-sm text-muted-foreground">
          Configure the basics of your compensation plan.
        </p>
      </div>
      <Separator />
      <BasicsForm plan={plan} tokens={tokens} mutate={mutate} />
    </div>
  );
};
