// import Image from "next/image";
// import Link from "next/link";

import { Card, CardContent } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { NestedQuota } from "@revelate/types";
import type { KeyedMutator } from "swr";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getFullName, getUserInitials, sortByStringKey } from "@revelate/utils";
import { ConfirmDeletionDialog } from "../../confirm-deletion";
import { useParams } from "wouter";
import { deleteUserFromGoal } from "@/lib/supabase/goals";

export function EmployeesTable({
  quota,
  mutate,
}: {
  quota: NestedQuota;
  mutate: KeyedMutator<NestedQuota | null>;
}) {
  const { goals } = quota || {};
  const { goalId } = useParams();
  const goal = goals?.find((g) => g.id === Number(goalId));
  const { users } = goal || {};

  return (
    <Card x-chunk="dashboard-05-chunk-3">
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>

              <TableHead>Name</TableHead>
              <TableHead className="hidden sm:table-cell">Email</TableHead>

              {/* <TableHead className="text-right"></TableHead> */}
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users?.sort(sortByStringKey("first_name")).map((user, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Avatar className="h-9 w-9">
                    <AvatarImage
                      src={user?.avatar_url || ""}
                      alt={getFullName(user)}
                    />
                    <AvatarFallback>{getUserInitials(user)}</AvatarFallback>
                  </Avatar>
                </TableCell>
                <TableCell>
                  <div className="font-medium">{getFullName(user)}</div>
                </TableCell>

                <TableCell className="hidden sm:table-cell">
                  {user?.email}
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-3">
                    <ConfirmDeletionDialog
                      key={index}
                      deleteId={user.id.toString()}
                      deleteOperation={async (id) => {
                        if (!goal) return;
                        await deleteUserFromGoal(goal.id, id);
                        const updatedUsers = users.filter(
                          (user) => user.id !== id
                        );
                        mutate({
                          ...quota,
                          goals: goals?.map((g) =>
                            g.id === goal.id ? { ...g, users: updatedUsers } : g
                          ),
                        } as NestedQuota);
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
