import * as React from "react";
import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { getFriendlyNameOfMonths } from "@revelate/utils";

type Checked = DropdownMenuCheckboxItemProps["checked"];

export function MonthPicker({
  months,
  setMonths,
}: {
  months: number[] | undefined;
  setMonths: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  const handleMonths = (month: number, checked: boolean) => {
    // Add or remove the month from the months array of numbers
    const newMonths = checked
      ? [...(months || []), month]
      : (months || []).filter((m) => m !== month);

    // Sort the months array
    newMonths.sort((a, b) => a - b);
    setMonths(newMonths);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {/* <Button variant="outline">Open</Button> */}
        <Button variant="outline" className="flex w-xl">
          {getFriendlyNameOfMonths(months)}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Select months below</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {
          // Loop for every month
          Array.from({ length: 12 }, (_, i) => (
            <DropdownMenuCheckboxItem
              onSelect={(e) => e.preventDefault()}
              key={i}
              checked={months?.includes(i + 1)}
              // checked={months?.includes(i + 1)}
              onCheckedChange={(checked) => handleMonths(i + 1, checked)}
            >
              {new Date(0, i).toLocaleString("default", { month: "long" })}
            </DropdownMenuCheckboxItem>
          ))
        }
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
