import {
  NestedUser,
  NestedPlan,
  DateRange,
  NestedDeal,
  GroupedCommissions,
  KeyDeal,
} from "@revelate/types";
import { criteriaMet } from "./accelerators";
import {
  CommissionStatus,
  CommissionUser,
  NestedCommission,
  NestedCompany,
  Scope,
  TODO,
} from "@revelate/types";
import {
  format,
  parse,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { enUS } from "date-fns/locale";
import { getUserIdsForTeam } from "./teams";
import { getDealsForDateRange, getDealValueFromCommissions } from "./deals";
import { getStartEndDates, shouldCalculate } from "@revelate/utils";
import { getExchangeRateForDeal } from "./currencies";

const getCommissionBasedOn = (
  deal: NestedDeal,
  commission_based_on: string,
  includeProjections: boolean = false
) => {
  let value = 0;

  switch (commission_based_on) {
    case "deal_value":
      value = deal.value || 0;
      break;
    case "onboarding_value":
      value = deal.onboarding_value ? deal.onboarding_value : 0;
      break;
    case "deal_value_arr":
      value = deal.value * 12 || 0;
      break;
    case "deal_value_above_target":
      console.log("Not implemented deal_value_above_target yet");
      return 0;
    default:
      value = deal.value || 0;
  }

  const projectionMultiplier = includeProjections
    ? deal.likelihood_to_close || 1
    : 1;
  return value * projectionMultiplier;
};

const getCommissionBasis = (
  company: NestedCompany,
  deal: NestedDeal,
  commission_based_on?: string,
  includeProjections: boolean = false
) => {
  // If fixed amount, just return the value

  const value = getCommissionBasedOn(
    deal,
    commission_based_on || "",
    includeProjections
  );
  return value * getExchangeRateForDeal(company, deal);
};

export const calculateCommissionsForPlan = (
  calculationDate: Date,
  dateRange: DateRange,
  company: NestedCompany,
  user: NestedUser,
  plan: NestedPlan,
  includeProjections: boolean = false,
  logResult: boolean = false
) => {
  if (!user || !plan || !dateRange) return null;

  const { time_period, deal_types, providers, accelerators } = plan || {};

  // Check if we should use this plan for the given date
  if (!shouldCalculate(calculationDate, time_period) || !time_period)
    return null;

  // Filter the deals for the given time period in case we're looping through multiple
  let deals: NestedDeal[] = getDealsForDateRange({
    deals: user.deals,
    dateRange: getStartEndDates(dateRange, calculationDate, time_period),
    dealTypes: deal_types,
    providers: providers || undefined,
    includeProjections,
  });

  if (includeProjections) {
    deals = deals.filter((d) => !d.is_lost);
  } else {
    deals = deals.filter((d) => d.is_won);
  }

  const commissions: NestedCommission[] = [];
  const dealsPassed: NestedDeal[] = [];

  // User can now have multiple accelerators per plan that kick in
  // So we need to loop through all of them and check the conditions for each one
  if (!accelerators || accelerators.length === 0) return null;

  // Order accelerators by highest rate
  const sortedAccelerators = accelerators.sort((a, b) => b.rate - a.rate);
  for (const accelerator of sortedAccelerators) {
    const { commission_based_on, fixed_value_amount, rate } = accelerator;

    if (commission_based_on === "deal_value_above_target") {
      // console.log("Not implemented deal_value_above_target yet");
      continue;
    }

    // Check if we meet the criteria for the accelerator, and filter the deals
    const { success, filteredDeals } = criteriaMet(
      calculationDate,
      dateRange,
      company,
      user,
      plan,
      accelerator,
      deals,
      includeProjections,
      logResult
    );
    if (!success) {
      // console.log("Criteria not met for ", accelerator.rate, " in ", plan.name);
      continue;
    }
    // Loop through every individual deal to create a commission
    for (const deal of filteredDeals) {
      const commission_basis =
        commission_based_on === "fixed_value" && fixed_value_amount
          ? fixed_value_amount
          : getCommissionBasis(
              company,
              deal,
              commission_based_on,
              includeProjections
            );
      const amount = commission_basis * rate;
      const commission: NestedCommission = {
        company_id: company.id,
        month: Number(format(calculationDate, "MM")),
        year: Number(format(calculationDate, "yyyy")),
        user_id: user.id,
        plan_id: plan.id,
        deal_id: deal.id,
        deal: deal,
        user,
        accelerator: accelerator,
        accelerator_id: accelerator.id,
        rate,
        plan,
        commission_basis,
        amount,
        status: includeProjections ? "projected" : "unapproved",
        // deals: [deal],
        created_at: new Date() as TODO,
        updated_at: new Date() as TODO,
      };
      dealsPassed.push(deal);
      commissions.push(commission);
    }
    if (logResult)
      console.log(
        "Accelerator matched:",
        `${accelerator.name} (ID: ${accelerator.id})`,
        "\nRate:",
        accelerator.rate,
        "\nCommission basis:",
        commissions.reduce(
          (acc, commission) => acc + commission.commission_basis,
          0
        ),
        "\nAmount to be paid:",
        commissions.reduce((acc, commission) => acc + commission.amount, 0)
      );

    break;
  }

  return commissions;
};

export const calculateCommissionForUser = (
  dateRange: DateRange,
  company: NestedCompany,
  user: NestedUser,
  includeProjections: boolean = false,
  logResult = false
) => {
  const allCommissions: NestedCommission[] = [];
  // For each plan for the give time period, calculate the commission
  const { plans } = user;
  if (!plans || (plans && plans.length === 0)) {
    // console.log("No plans found for user ", user.email);
    return allCommissions;
  }

  const { from, to } = dateRange || {};
  if (!from || !to) return allCommissions;

  const d = new Date(from);
  while (d <= new Date(to)) {
    for (const plan of plans) {
      const commissions = calculateCommissionsForPlan(
        d,
        dateRange,
        company,
        user,
        plan,
        includeProjections,
        logResult
      );
      if (commissions && commissions.length > 0)
        // Combine all commissions for the user
        allCommissions.push(...commissions);
      // const commission = await upsertCommission(data);
      // if (!commission) return;
      // for (const deal_id of deal_ids) {
      //   upsertCommissionDeal(commission.id, deal_id);
      // }
    }
    d.setMonth(d.getMonth() + 1);
  }
  if (logResult)
    console.log(
      "Calculated commissions for user ",
      user.email,
      ": ",
      allCommissions
    );
  return allCommissions;
};

export const calculateCommissionsForUsers = (
  dateRange: DateRange,
  company: NestedCompany,
  users: NestedUser[],
  includeProjections: boolean = false,
  logResult = false
) => {
  return company
    ? users
        .map((user) => {
          const commissions = calculateCommissionForUser(
            dateRange,
            company,
            user,
            includeProjections,
            logResult
          );
          return {
            // ...user,
            commissions,
          };
        })
        .flatMap((user) => user.commissions)
    : [];
};

export const getCommissionsValue = (commissions: NestedCommission[]) => {
  if (!commissions) return 0;
  return commissions?.reduce((acc, commission) => acc + commission.amount, 0);
};

export const getCommissionForDateRange = (
  commissions: NestedCommission[],
  dateRange?: DateRange,
  filterOnStatuses?: CommissionStatus[]
) => {
  const { from, to } = dateRange || {};
  if (!from || !to) return [];
  const scopedCommissions = commissions?.filter((commission) => {
    const commissionDate = parse(
      `${commission.year}-${commission.month}`,
      "yyyy-MM",
      new Date()
    );
    if (
      filterOnStatuses &&
      commission.status &&
      !filterOnStatuses.includes(commission.status)
    )
      return false;
    return (
      from &&
      to &&
      isWithinInterval(commissionDate, {
        start: startOfDay(from),
        end: endOfDay(to),
      })
    );
  });
  return scopedCommissions;
};

export const getTotalCommissionOfUsers = (users: CommissionUser[]) => {
  return users.reduce((acc, user) => acc + user.value, 0);
};

export const getMonthName = (commission: NestedCommission) => {
  const { year, month } = commission;
  // Get name of month from number
  const date = new Date(year, month - 1);
  return format(date, "MMMM", { locale: enUS });
};

export const getUsersForScope = (
  company: NestedCompany,
  scope?: Scope | null
) => {
  const { users, teams } = company || {};
  // Get users for scope
  if (!scope) return users;
  if (scope.type === "user") {
    return users.filter((user) => user.id === scope.value);
  }
  // TODO: Add team scope by filtering on users within a team
  if (scope.type === "team") {
    const userIds = getUserIdsForTeam(teams, Number(scope.value));
    return users.filter((user) => userIds.includes(user.id));
  }
  return users;
};

export const getPaidCommissions = (commissions: NestedCommission[]) => {
  return commissions.filter((commission) => commission.status === "paid");
};

export const getKeyDeals = (
  groupedCommissions: GroupedCommissions,
  limit: number = 3
): KeyDeal[] => {
  const keyDeals = Object.entries(groupedCommissions)
    .map(([dealName, commissions]) => {
      const isDealWon = commissions?.[0]?.deal?.is_won;
      const isDealLost = commissions?.[0]?.deal?.is_lost;
      if (isDealWon || isDealLost) return null;

      const dealValue = getDealValueFromCommissions(commissions);
      const commissionValue = getCommissionsValue(commissions);
      const owner = commissions?.[0]?.user;
      return {
        dealName,
        dealValue,
        owner,
        commissionValue,
      } satisfies KeyDeal;
    })
    .filter((kd) => kd !== null)
    .toSorted((a, b) => b.dealValue - a.dealValue)
    .slice(0, limit);

  return keyDeals;
};

export const getCommissionsByQuotaTargetKeyDeals = (
  commissions: NestedCommission[],
  limit: number = 3
): NestedCommission[] => {
  return commissions
    .filter((c) => !c.deal?.is_won)
    .toSorted((a, b) => b.amount - a.amount)
    .slice(0, limit);
};
