import { useToast } from "@/components/ui/use-toast";
import {
  getDateRangeSyncUrl,
  getMagicLinkUrl,
  getProviderSyncUrl,
} from "@/lib/api";
import { useAppStore } from "@/stores";
import { useState } from "react";
import { DateRange } from "react-day-picker";

export const useAPI = () => {
  const currentUser = useAppStore((state) => state.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const handleAPIError = async (response: Response) => {
    const { status } = response || {};

    // An error occurred
    const errorText = await response.text();
    if (status === 401) {
      toast({
        title: errorText || "Failed to renew token",
        variant: "destructive",
        description: "You may need to reauthenticate the provider.",
        // action: (
        //   <ToastAction
        //     onClick={() =>
        //       window.open(getAuthUrl(company.id, errorText), "_blank")
        //     }
        //     altText="Reauthenticate"
        //   >
        //     Reauthenticate
        //   </ToastAction>
        // ),
        duration: 20000,
      });
    } else {
      toast({
        title: "Could not sync data",
        variant: "destructive",
        description: errorText,
        // action: (
        //   <ToastAction onClick={handleClick} altText="Try again">
        //     Try again
        //   </ToastAction>
        // ),
        duration: 20000,
      });
    }

    return response;
  };

  // const handleAPISuccess = async (response: Response, scheduled = true) => {
  //   const data = await response.json();
  //   const { count } = data || {};
  //   if (!count) {
  //     toast({
  //       title: "No deals found",
  //       description: "No deals found for the selected time period.",
  //       duration: 10000,
  //     });
  //   } else {
  //     if (scheduled) {
  //       toast({
  //         title: "Synchronization scheduled",
  //         description: `${count} deals are scheduled to sync for the selected time period.`,
  //         duration: 10000,
  //       });
  //     } else {
  //       toast({
  //         title: `Synchronization completed`,
  //         description: `${count} deals were successfully synced.`,
  //         duration: 10000,
  //       });
  //     }
  //   }
  //   return response;
  // };

  const getError = (chunks: string[]) => {
    return chunks.find((c) => c.toLowerCase().includes("error"));
  };

  const hasError = (chunks: string[]) => {
    return chunks.some((c) => c.toLowerCase().includes("error"));
  };

  const tryFetchCatch = async (url: string) => {
    // try {
    setIsLoading(true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_API_KEY}`,
      },
    })
      .then((response) => {
        // Get the readable stream from the response body
        const stream = response.body;
        toast({
          title: `Synchronization initiated`,
          description: `Syncing data. This may take up to a minute.`,
          duration: 60000,
        });
        // Get the reader from the stream
        const reader = stream?.getReader();
        const chunks: string[] = [];

        // Define a function to read each chunk
        const readChunk = () => {
          // Read a chunk from the reader
          reader
            ?.read()
            .then(({ value, done }) => {
              // Check if the stream is done
              if (done) {
                // Log a message
                if (chunks.length > 0) {
                  if (hasError(chunks)) {
                    toast({
                      title: "Could not sync data",
                      variant: "destructive",
                      description: getError(chunks),
                      duration: 10000,
                    });
                  } else {
                    toast({
                      title: `Synchronization completed`,
                      description: `${chunks.length} deals were successfully synced.`,
                      duration: 10000,
                    });
                  }
                } else {
                  toast({
                    title: "Nothing to sync",
                    description:
                      "No deals found for the selected time period. Reconnect integrations if you believe this is an error.",
                    duration: 10000,
                  });
                }
                setIsLoading(false);
                // Return from the function
                return;
              }
              // Convert the chunk value to a string

              const stringValue = new TextDecoder().decode(value, {
                stream: true,
              });
              // // Log the chunk string
              // console.log("value", chunkString);
              chunks.push(stringValue);

              // const json = JSON.parse(chunkString);
              // Read the next chunk
              readChunk();
            })
            .catch((error) => {
              // Log the error
              console.error(error);
            });
        };
        // Start reading the first chunk
        readChunk();
        return new Response();
      })
      .catch((error) => {
        // Log the error
        console.error(error);
        return new Response();
      });
    //   const reader = response?.body?.getReader();

    //   return new ReadableStream({
    //     start(controller) {
    //       return pump();
    //       function pump() {
    //         return reader.read().then(({ done, value }) => {
    //           // When no more data needs to be consumed, close the stream
    //           if (done) {
    //             controller.close();
    //             return;
    //           }
    //           // Enqueue the next data chunk into our target stream
    //           controller.enqueue(value);
    //           return pump();
    //         });
    //       }
    //     },
    //   });
    //   while (true) {
    //     const { value, done } = (await reader?.read()) || {};

    //     if (done) {
    //       console.log("The stream was already closed!");
    //       setIsLoading(false);
    //       break;
    //     } else {
    //       console.log(value);
    //     }
    //   }
    //   // setIsLoading(false);
    //   // if (!response.ok) return handleAPIError(response);
    //   return handleAPISuccess(response, scheduled);
    // } catch (error) {
    //   setIsLoading(false);
    //   return handleAPIError(
    //     new Response("An error occurred while fetching data.")
    //   );
    // }
  };

  const syncDealsByDateRange = async (
    dateRange: DateRange
  ): Promise<Response> => {
    if (!currentUser) return handleAPIError({} as Response);
    return tryFetchCatch(
      getDateRangeSyncUrl(currentUser.company_id, dateRange)
    );
  };

  const syncDealsByProvider = async (
    provider: string,
    providerId?: string
  ): Promise<Response> => {
    if (!currentUser) return handleAPIError({} as Response);
    return tryFetchCatch(
      getProviderSyncUrl(provider, currentUser.company_id, providerId)
    );
  };

  const getMagicLink = async (email: string) => {
    // Create a fetch query to sign in with Magic Link
    const response = await fetch(getMagicLinkUrl(email), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_API_KEY}`,
      },
    });
    if (!response.ok) {
      return handleAPIError(response);
    }
    return await response.json();
  };

  return {
    syncDealsByDateRange,
    syncDealsByProvider,
    getMagicLink,
    isLoading,
  };
};
