import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { TODO } from "@revelate/types";
import { capitalizeFirstLetter } from "@revelate/utils";
import { format } from "date-fns";

export const ProviderDataViewer = ({ row }: { row: TODO }) => {
  const deal = row.original;
  const { id, name, updated_at, provider, provider_data } = deal;
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="lg:max-w-screen-md overflow-y-scroll max-h-screen">
        <DialogHeader>
          <DialogTitle>
            Deal {id}: {name}
          </DialogTitle>
          <DialogDescription>
            {provider_data
              ? `The following source data was parsed from
            ${capitalizeFirstLetter(provider)} on
            ${format(new Date(updated_at), "yyyy-MM-dd HH:mm:ss")}.`
              : "Synchronize this deal to obtain source data from the provider."}
          </DialogDescription>
        </DialogHeader>
        {provider_data && (
          <div className="grid gap-4">
            <div className="rounded-md bg-[#000000] p-6">
              <pre>
                <code className="grid gap-1 font-mono text-sm text-[#ffffff] [&_span]:h-4">
                  {JSON.stringify(JSON.parse(provider_data), null, 2)}
                </code>
              </pre>
            </div>
            <div>
              <p className="text-sm text-muted-foreground"></p>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
