import {
  Database,
  DateRange,
  NestedCompany,
  NestedDeal,
  NestedPlan,
  NestedUser,
} from "@revelate/types";
import { CriteriaResult } from "./accelerators";
import { getDealValue } from "./deals";
import { checkQuotaCondition } from "./quotas";

const checkBasicCondition = (
  actualValue: number | null,
  condition: Database["public"]["Tables"]["conditions"]["Row"]
) => {
  if (actualValue === null) return false;
  const { operator, value, value_from, value_to, value_list } = condition;
  if (operator === "is" && value) {
    return actualValue === value;
  }
  if (operator === "gte" && value) {
    return actualValue >= value;
  }
  if (operator === "lte" && value) {
    return actualValue <= value;
  }
  if (operator === "range" && value_from && value_to) {
    return actualValue >= value_from && actualValue <= value_to;
  }
  if (operator === "list" && value_list) {
    return value_list.includes(actualValue.toString());
  }
  return false;
};

export const conditionMet = (
  calculationDate: Date,
  dateRange: DateRange,
  company: NestedCompany,
  user: NestedUser,
  plan: NestedPlan,
  condition: Database["public"]["Tables"]["conditions"]["Row"],
  deals: NestedDeal[],
  includeProjections: boolean = false,
  logResult: boolean = false
): CriteriaResult => {
  const { key } = condition;
  let success = false;
  if (!key)
    return {
      success,
      filteredDeals: [],
    };
  let filteredDeals = deals;
  if (key === "deals_size_value") {
    filteredDeals = filteredDeals?.filter((deal) =>
      checkBasicCondition(getDealValue(company, deal), condition)
    );
    success = filteredDeals.length > 0;
  }
  if (key === "deals_size_arr_value") {
    filteredDeals = filteredDeals?.filter((deal) =>
      checkBasicCondition(getDealValue(company, deal) * 12, condition)
    );
    success = filteredDeals.length > 0;
  }
  if (key === "deals_invoice_length_months") {
    filteredDeals = filteredDeals?.filter((deal) =>
      checkBasicCondition(deal.invoice_length_months, condition)
    );
    success = filteredDeals.length > 0;
  }
  if (key === "deals_customer_deal_count") {
    filteredDeals = filteredDeals?.filter((deal) =>
      checkBasicCondition(deal.customer_deal_count, condition)
    );
    success = filteredDeals.length > 0;
  }
  if (key === "deals_contract_length_months") {
    filteredDeals = filteredDeals?.filter((deal) =>
      checkBasicCondition(deal.contract_length_months, condition)
    );
    success = filteredDeals.length > 0;
  }

  if (key.includes("quota")) {
    const { success: quotaSuccess, filteredDeals: quotaFilteredDeals } =
      checkQuotaCondition(
        calculationDate,
        dateRange,
        company,
        user,
        plan,
        condition,
        deals,
        filteredDeals,
        includeProjections,
        logResult
      );
    success = quotaSuccess;
    filteredDeals = quotaFilteredDeals;
  }
  return {
    success,
    filteredDeals,
  };
};
