import { useEffect, useState } from "react";
import { AnalyticsBrowser } from "@june-so/analytics-next";

export function useJune() {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | undefined>(
    undefined
  );

  useEffect(() => {
    const loadAnalytics = async () => {
      const response = AnalyticsBrowser.load({
        writeKey: import.meta.env.VITE_JUNE_WRITE_KEY,
      });
      setAnalytics(response);
    };
    loadAnalytics();
  }, []);

  return analytics;
}
