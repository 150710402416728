import { supabaseClient } from "./client";
import { Database, NestedUser } from "@revelate/types";

export const transferDeals = async (
  fromUser: NestedUser,
  toUser: NestedUser
) => {
  const { id: fromUserId } = fromUser;
  const { id: toUserId, provider_id } = toUser;

  const query = supabaseClient
    .from("deals")
    .update({ owner_id: toUserId, provider_owner_id: provider_id })
    .eq("owner_id", fromUserId);
  const { error } = await query;
  if (error) {
    console.error("Error transferring deals", error);
    throw error;
  }
};

export const updateDeal = async (
  dealId: number,
  data: Partial<Database["public"]["Tables"]["deals"]["Row"]>
) => {
  const { error } = await supabaseClient
    .from("deals")
    .update(data)
    .eq("id", dealId);
  if (error) {
    console.error("Error updating deal", error);
    throw error;
  }
};
