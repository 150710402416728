import { ConfirmDeletionDialog } from "@/components/ConfirmDeletionDialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import { Integration } from "@/constants/integrations";
import {
  getProviderAuthUrl,
  getToken,
  hasToken,
  isExpired,
} from "@/lib/app/tokens";
import { deleteToken } from "@/lib/supabase/tokens";
import { NestedCompany, NestedToken } from "@revelate/types";
import { X, Link as LinkIcon } from "lucide-react";
import { KeyedMutator } from "swr";
import { Link } from "wouter";
import QRCode from "react-qr-code";

export function IntegrationCard({
  integration,
  tokens,
  company,
  mutate,
}: {
  integration: Integration;
  tokens: NestedToken[];
  company: NestedCompany;
  mutate: KeyedMutator<NestedToken[]>;
}) {
  const { name, description, icon, id, available } = integration || {};
  const tokenId = getToken(tokens, id)?.id.toString() || "";
  const tokenExpired = isExpired(tokens, id);
  const tokenExists = hasToken(tokens, id);
  return (
    <Card className="h-full">
      <CardHeader className="space-y-2">
        {available && (
          <div className="align-left pb-5">
            <QRCode
              opacity={!tokenExists || tokenExpired ? 1 : 0.3}
              className="w-auto h-28"
              value={getProviderAuthUrl(company.id, id)}
            />
          </div>
        )}
        <div className="">
          <img src={icon} alt={name} className="max-h-12 w-36" />
        </div>
        <CardTitle className="text-2xl">
          <div className="mb-4 align-middle">
            <span className="font-semibold mr-2 align-middle">{name}</span>
            {available && (
              <Badge
                // className="font-medium"
                variant={
                  hasToken(tokens, id)
                    ? isExpired(tokens, id)
                      ? "earned"
                      : "paid"
                    : "secondary"
                }
              >
                {hasToken(tokens, id)
                  ? isExpired(tokens, id)
                    ? "Connection expired"
                    : "Successfully connected"
                  : "Not connected"}
              </Badge>
            )}
          </div>
        </CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4">
        {!available ? (
          <Button disabled className="w-full" variant="outline">
            Unavailable
          </Button>
        ) : tokenExists && !tokenExpired ? (
          <ConfirmDeletionDialog
            key={`delete-${tokenId}`}
            title={`Are you sure you want to disconnect ${name}?`}
            description="You will have to reconnect the provider to sync data again."
            deleteId={tokenId}
            deleteOperation={async (tokenId) => {
              await deleteToken(Number(tokenId));
              const updatedTokens = tokens.filter(
                (token) => token.id !== Number(tokenId)
              );
              mutate(updatedTokens);
            }}
          >
            <Button variant="outline">
              <X className="mr-2 h-4 w-4" />
              Disconnect
            </Button>
          </ConfirmDeletionDialog>
        ) : (
          <Link href={getProviderAuthUrl(company.id, id)} target="_blank">
            <Button
              onClick={() =>
                window.open(getProviderAuthUrl(company.id, id), "_blank")
              }
              className="w-full"
              variant={tokenExpired ? "secondary" : "default"}
            >
              <LinkIcon className="mr-2 h-4 w-4" />
              {tokenExpired ? "Reconnect" : "Connect"}
            </Button>
          </Link>
        )}
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}
