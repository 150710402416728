export const getPercentage = (current: number, previous?: number) => {
  if (current === 0 || (previous && previous === 0)) return "0%";
  // Number(dealCount / dealCountPrevious).toLocaleString(
  //   undefined,
  //   {
  //     style: "percent",
  //     minimumFractionDigits: 0,
  //   }
  // )
  // const difference = current - previous;
  const percentage = previous ? current / previous : current;
  if (isNaN(percentage)) return "0%";
  return Number(percentage).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });
};

export function getPercentageNumeric(current: number, previous?: number) {
  if (current === 0 || (previous && previous === 0)) return 0;
  const percentage = previous ? current / previous : current;
  if (isNaN(percentage)) return 0;
  return Number(percentage);
}

export function stringifyPercentage(number: number) {
  return number.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });
}
