import * as React from "react";

import { cn } from "@/lib/utils";
import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { supabaseClient } from "@/lib/supabase/client";
import { TODO } from "@revelate/types";
import { OtpDialog } from "./otp-dialog";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");

  const signIn = async (email: string) => {
    if (!email) return;
    setIsLoading(true);
    const { error } = await supabaseClient.auth.signInWithOtp({
      email: email.trim(),
      options: { emailRedirectTo: import.meta.env.VITE_SITE_URL || "/" },
    });
    setIsLoading(false);
    if (error) {
      console.log("Error signing in", error);
    } else {
      setDialogOpen(true);
    }
  };

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    const email = (event.target as TODO)["email"].value;
    setEmail(email);
    signIn(email);
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="email">Work Email</Label>
            <Input
              required
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
            />
          </div>
          <Button disabled={isLoading} style={{ backgroundColor: "#6B6397" }}>
            {isLoading && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Continue
          </Button>
        </div>
      </form>
      {/* <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            Or continue with
          </span>
        </div>
      </div>
      <Button variant="outline" type="button" disabled={isLoading}>
        {isLoading ? (
          <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <Icons.gitHub className="mr-2 h-4 w-4" />
        )}{" "}
        GitHub
      </Button> */}
      <OtpDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        email={email}
        signIn={signIn}
      />
    </div>
  );
}
