import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Mail } from "lucide-react";

export function OtpDialog({
  open,
  setOpen,
  email,
  signIn,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  email: string;
  signIn: (email: string) => void;
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <img src="/inbox.svg" alt="Inbox" className="mx-8 mt-10" />
          <DialogTitle>Check your inbox</DialogTitle>
          <DialogDescription>
            We've emailed <b>{email}</b> with a magic link that you can use to
            sign in.{" "}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button
            onClick={() => signIn(email)}
            type="submit"
            style={{ backgroundColor: "#6B6397" }}
          >
            <Mail className="mr-2 h-4 w-4" />
            Resend email
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
