import {
  NestedCompany,
  NestedCurrency,
  NestedExchangeRate,
} from "@revelate/types";
import { isAfter } from "date-fns";

export const getCurrencyFormatted = (
  amount: number,
  currency = "SEK",
  locale = "sv-SE"
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const getNumberFormatted = (amount: number, locale = "sv-SE") => {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 0,
  }).format(amount);
};

export const getExchangeRateToDefaultCurrency = (
  currency: NestedCurrency,
  defaultCurrency?: NestedCurrency
): NestedExchangeRate | null => {
  if (!defaultCurrency) return null;
  const exchangeRate = currency.exchange_rates.find(
    (exchangeRate) =>
      exchangeRate.to_currency_id === defaultCurrency.id &&
      (exchangeRate.valid_to === null ||
        isAfter(new Date(exchangeRate.valid_to), new Date()))
  );
  return exchangeRate || null;
};

export const isDefaultCurrency = (
  company: NestedCompany,
  currencyId: number
) => {
  return company?.default_currency_id === currencyId;
};
