import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { BarChart, Check, Coins, PiggyBank } from "lucide-react";
import {
  getApproved,
  getCurrencyFormatted,
  getPaid,
  getUnapproved,
} from "@/lib/app";
import { getCommissionsValue } from "@revelate/calc";
import { NestedCommission } from "@revelate/types";

interface CommissionKeyValuesProps {
  commissions: NestedCommission[];
  projectedCommissions: NestedCommission[];
}

export function CommissionKeyValues({
  commissions,
  projectedCommissions,
}: CommissionKeyValuesProps) {
  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Projected commission
          </CardTitle>
          <BarChart className="h-4 w-4" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {getCurrencyFormatted(getCommissionsValue(projectedCommissions))}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Earned commission
          </CardTitle>
          <Coins className="h-4 w-4" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {getCurrencyFormatted(
              getCommissionsValue(getUnapproved(commissions)) +
                getCommissionsValue(getApproved(commissions)) +
                getCommissionsValue(getPaid(commissions))
            )}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Approved commission
          </CardTitle>

          <Check className="h-4 w-4" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {getCurrencyFormatted(
              getCommissionsValue(getApproved(commissions)) +
                getCommissionsValue(getPaid(commissions))
            )}
          </div>
          {/* <p className="text-xs text-muted-foreground">
                      {getDifferenceToPreviousPeriod(
                        commission,
                        commissionPrevious
                      )}
                    </p> */}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Paid commission</CardTitle>
          <PiggyBank className="h-4 w-4" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {getCurrencyFormatted(getCommissionsValue(getPaid(commissions)))}
          </div>
          {/* <p className="text-xs text-muted-foreground">
                      {getDifferenceToPreviousPeriod(
                        commission,
                        commissionPrevious
                      )}
                    </p> */}
        </CardContent>
      </Card>
    </div>
  );
}
