import {
  AcceleratorWithConditions,
  DateRange,
  NestedCompany,
  NestedDeal,
  NestedPlan,
  NestedUser,
} from "@revelate/types";
import { conditionMet } from "./conditions";

export type CriteriaResult = {
  success: boolean;
  filteredDeals: NestedDeal[];
};

export const criteriaMet = (
  calculationDate: Date,
  dateRange: DateRange,
  company: NestedCompany,
  user: NestedUser,
  plan: NestedPlan,
  accelerator: AcceleratorWithConditions,
  deals: NestedDeal[],
  includeProjections: boolean = false,
  logResult: boolean = false
): CriteriaResult => {
  const { criteria, conditions } = accelerator;
  if (criteria === "always")
    return {
      success: true,
      filteredDeals: deals,
    };
  // Keep filtering the deals for every condition
  let filteredDeals = deals;
  const successes: boolean[] = [];
  for (const condition of conditions) {
    const {
      filteredDeals: filteredDealsFromCondition,
      success: successFromCondition,
    } = conditionMet(
      calculationDate,
      dateRange,
      company,
      user,
      plan,
      condition,
      filteredDeals,
      includeProjections,
      logResult
    );
    filteredDeals = filteredDealsFromCondition;
    successes.push(successFromCondition);
  }
  const success =
    criteria === "all_criteria_met"
      ? successes.every((s) => s)
      : criteria === "any_criteria_met"
        ? successes.some((s) => s)
        : false;
  return {
    success,
    filteredDeals,
  };
};
