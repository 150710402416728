// import Image from "next/image";
// import Link from "next/link";
import { Pencil, Plus } from "lucide-react";

import { Badge } from "@/components/ui/badge";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useAppStore } from "@/stores";
import { useLocation } from "wouter";
import useSWR from "swr";
import { Loader } from "@/components/Loader";
import { LoadingError } from "@/components/LoadingError";
import { ConfirmDeletionDialog } from "./edit/confirm-deletion";
import { getRoute } from "@/lib/routes";
import { deleteQuota } from "@/lib/supabase/quotas";
import { sortByStringKey } from "@revelate/utils";
import {
  getCalculationPeriodLabelFromValue,
  getCommissionDealTypeLabels,
  getQuotaLabel,
  getGoalUsersCountFromQuota,
} from "@/lib/app";
import { TableType, getAll } from "@/lib/supabase";

export function QuotasPage() {
  const appState = useAppStore((state) => state);
  const { scope, currentUser } = appState;
  const {
    data: quotas,
    error,
    isLoading,
    mutate,
  } = useSWR(
    [
      "quotas" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId)
  );

  const [, setLocation] = useLocation();

  if (isLoading || !quotas) {
    return <Loader />;
  }
  if (error) {
    return <LoadingError />;
  }

  return (
    <main className="grid flex-1 items-start gap-4  sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-3">
        <Card x-chunk="dashboard-05-chunk-3">
          <div className="flex">
            <CardHeader className="flex px-7">
              <CardTitle>Sales Quotas</CardTitle>
              <CardDescription>
                Track target attainment for your sales team.
              </CardDescription>
            </CardHeader>
            <div className="ml-auto flex items-center gap-2 pr-7">
              <Button
                onClick={() => setLocation(getRoute("quotas", "quota", "new"))}
                className="bg-[#6B6397]"
              >
                <Plus className="mr-2 h-4 w-4" />
                Add new quota
              </Button>
            </div>
          </div>
          <CardContent>
            {quotas && quotas.length > 0 ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>

                    <TableHead className="hidden sm:table-cell">
                      Target Metric
                    </TableHead>

                    {/* <TableHead className="hidden sm:table-cell">
                      Calculation Period
                    </TableHead> */}
                    <TableHead className="hidden sm:table-cell">
                      Deal Types
                    </TableHead>
                    <TableHead className="hidden sm:table-cell">
                      Sales Goals
                    </TableHead>
                    <TableHead className="text-right"></TableHead>
                    <TableHead></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {quotas.sort(sortByStringKey("name")).map((quota, index) => (
                    <TableRow
                      className={
                        scope?.type === "quota" &&
                        quota.id === Number(scope?.value)
                          ? "bg-accent"
                          : ""
                      }
                      key={index}
                    >
                      <TableCell>
                        <div className="font-medium">{quota.name}</div>
                        <div className="hidden text-sm text-muted-foreground md:inline">
                          {getGoalUsersCountFromQuota(quota) > 0
                            ? `${getGoalUsersCountFromQuota(
                                quota
                              )} employees assigned`
                            : "No employees assigned yet"}
                        </div>
                      </TableCell>

                      <TableCell className="hidden sm:table-cell">
                        {getQuotaLabel(quota)}
                      </TableCell>
                      {/* <TableCell className="hidden sm:table-cell">
                        {getCalculationPeriodLabelFromValue(
                          quota.calculation_period
                        )}
                      </TableCell> */}
                      <TableCell className="hidden sm:table-cell">
                        <div className="font-medium">
                          {" "}
                          All {getCommissionDealTypeLabels(quota.deal_types)}
                        </div>
                        <div className="hidden text-sm text-muted-foreground md:inline">
                          {getCalculationPeriodLabelFromValue(
                            quota.calculation_period
                          )}
                        </div>
                      </TableCell>
                      <TableCell className="hidden sm:table-cell">
                        <Badge
                          className="text-xs"
                          variant={
                            quota?.goals?.length > 0 ? "paid" : "destructive"
                          }
                        >
                          {quota?.goals?.length > 0
                            ? `${quota?.goals?.length} goals set`
                            : "Missing"}
                        </Badge>
                      </TableCell>

                      <TableCell className="text-right"></TableCell>
                      <TableCell>
                        <div className="flex items-center gap-3">
                          <Button
                            onClick={() =>
                              setLocation(
                                getRoute("quotas", "quota", quota.id.toString())
                              )
                            }
                            variant="default"
                            size="icon"
                            className="bg-[#6B6397]"
                          >
                            <Pencil className="h-4 w-4" />
                          </Button>

                          <ConfirmDeletionDialog
                            key={index}
                            deleteId={quota.id.toString()}
                            deleteOperation={async (id) => {
                              await deleteQuota(Number(id));
                              const updatedQuotas = quotas.filter(
                                (quota) => quota.id !== Number(id)
                              );
                              mutate(updatedQuotas);
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <p className="text-lg text-muted-foreground text-center">
                Add your first quota to personalize sales goals 🎯
              </p>
            )}
          </CardContent>
        </Card>
      </div>
    </main>
  );
}
