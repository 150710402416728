import { Database, NestedQuota } from "@revelate/types";
import { supabaseClient } from "./client";

export const getQuotas = async (companyId: string): Promise<NestedQuota[]> => {
  const query = supabaseClient
    .from("quotas")
    .select("*, users(*)")
    .eq("company_id", Number(companyId));
  const { data, error } = await query.returns<NestedQuota[]>();
  if (error) {
    throw error;
  }
  return data;
};

export const deleteQuota = async (quotaId: number) => {
  const query = supabaseClient.from("quotas").delete().eq("id", quotaId);
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};

export const getQuota = async (quotaId: string) => {
  const query = supabaseClient
    .from("quotas")
    .select("*, users(*)")
    .eq("id", Number(quotaId))
    .returns<NestedQuota[]>();
  const { data, error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

export const upsertQuota = async (
  quota: Database["public"]["Tables"]["quotas"]["Insert"]
): Promise<NestedQuota | null> => {
  const query = supabaseClient.from("quotas").upsert({
    ...quota,
    updated_at: new Date(),
  });

  const { data, error } = await query
    .select("*, users(*)")
    .returns<NestedQuota[]>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

// export const addUserToQuota = async (quotaId: number, userId: string) => {
//   const query = supabaseClient
//     .from("user_quotas")
//     .insert({ user_id: userId, quota_id: quotaId });
//   const { error } = await query.maybeSingle();
//   if (error) {
//     throw error;
//   }
// };

// export const deleteUserFromQuota = async (quotaId: number, userId: string) => {
//   const query = supabaseClient
//     .from("user_quotas")
//     .delete()
//     .eq("user_id", userId)
//     .eq("quota_id", quotaId);
//   const { error } = await query;
//   if (error) {
//     throw error;
//   }
// };
